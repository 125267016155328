:root {
  @include use-responsive-sizing(--highlight-text-size, responsive-map(xs 1.8rem, xl 2.6rem));

  --highlight-text-font-weight: var(--font-weight-bold);
  --highlight-text-line-height: var(--line-height-body);
  --highlight-text-spacing: 2rem;
  --highlight-padding-inline: 0;
  --highlight-padding-block: 0;
}

.highlight-text {
  font-size: var(--highlight-text-size);
  font-weight: var(--highlight-text-font-weight);
  line-height: var(--highlight-text-line-height);
  padding-block: var(--highlight-padding-block);
  padding-inline: var(--highlight-padding-inline);

  > .horizontal-rule {
    --horizontal-rule-margin-block: var(--highlight-horizontal-rule-margin-block, calc(var(--highlight-text-spacing) * 1.5));
  }

  > * + *:not(.horizontal-rule) {
    margin-block-start: var(--highlight-text-spacing);
  }
}
