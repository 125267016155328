:root {
  @include use-responsive-sizing(--tile-font-size, responsive-map(xs 1.6rem, xl 2rem));

  --tile-aspect-ratio: 16 / 9;
  --tile-background-color-engaged: var(--color-red-600);
  --tile-background-color: var(--primary-color);
  --tile-color: var(--color-white);
  --tile-gap: 2rem;
  --tile-headline-size: 1.4em;
  --tile-icon-size: 2em;
  --tile-padding-block: 1.4em;
  --tile-padding-inline: 1.4em;
  --tile-z-index: #{z-index('default')};
}

.tile {
  align-items: flex-start;
  aspect-ratio: var(--tile-aspect-ratio);
  background-color: var(--tile-background-color);
  color: var(--tile-color);
  display: flex;
  flex-direction: column;
  font-size: var(--tile-font-size);
  gap: 2rem;
  padding-block: var(--tile-padding-block);
  padding-inline: var(--tile-padding-inline);
  position: relative;
  transition-property: background-color;
  z-index: var(--tile-z-index);

  &:is(:hover, :focus-within) {
    background-color: var(--tile-background-color-engaged);
  }
}

.tile__top {
  align-items: flex-start;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

.tile__headline {
  --line-clamp: 3;

  font-size: var(--tile-headline-size);
  font-weight: var(--font-weight-bold);
  hyphens: auto;
  line-height: var(--line-height-default);
}

.tile__icon {
  flex-shrink: 0;
  font-size: var(--tile-icon-size);
  margin-block-start: 0.1em;
}

.tile__description {
  flex-shrink: 0;
  line-height: var(--line-height-body);
  margin-block-start: auto;
  width: 100%;
}
