:root {
  --table-border-color: var(--background-color);
  --table-border-width: 2px;
  --table-caption-color: var(--meta-text-color);
  --table-caption-font-size: 1.4rem;
  --table-caption-gap: 1.5rem;
  --table-cell-padding-block: 1rem;
  --table-cell-padding-inline: 2rem;
  --table-color: currentColor;
  --table-font-size: 1.6rem;
  --table-head-font-size: 1.6rem;
  --table-head-font-weight: var(--font-weight-bold);
  --table-line-height: var(--line-height-body);
}

.table {
  border-collapse: collapse;
  color: var(--table-color);
  font-size: var(--table-font-size);
  line-height: var(--table-line-height);
  width: 100%;
}

.table__head-row,
.table__foot-row {
  background-color: var(--table-border-color);
  color: var(--table-header-color);
}

.table__head-cell,
.table__foot-cell {
  font-size: var(--table-head-font-size);
  font-weight: var(--table-head-font-weight);
}

.table__head-cell,
.table__cell,
.table__foot-cell {
  border: var(--table-border-width) solid var(--table-border-color);
  line-height: var(--line-height-body);
  padding-block: var(--table-cell-padding-block);
  padding-inline: var(--table-cell-padding-inline);
  text-align: left;
  vertical-align: text-top;
}

.table__caption {
  caption-side: bottom;
  color: var(--table-caption-color);
  font-family: var(--font-headline);
  font-size: var(--table-caption-font-size);
  line-height: var(--table-line-height);
  margin-block-start: var(--table-caption-gap);
  text-align: left;
}
