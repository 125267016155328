:root {
  --progress-height: 3rem;
  --progress-color: var(--text-color);
  --progress-font-size: 1.4rem;
  --progress-background-color: var(--background-color);
  --progress-border-color: var(--background-color);
  --progress-border-width: 1px;
}

.progress {
  border: var(--progress-border-width) solid var(--progress-border-color);
  overflow: hidden;
  position: relative;
}

.progress__progress {
  background-color: var(--progress-background-color);
  height: var(--progress-height);
  text-align: center;
}

.progress__label {
  color: var(--progress-color);
  font-size: var(--progress-font-size);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
