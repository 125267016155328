:root {
  --link-color: var(--interactive-color);
  --link-color-engaged: var(--text-color);
  --link-font-weight: inherit;
  --link-icon-color-engaged: inherit;
  --link-icon-color: inherit;
  --link-icon-gap: 0.3em;
  --link-icon-puffer: 0.2em;
  --link-icon-size: 0.9em;
  --link-icon-vertical-align: 0.1em;
  --link-underline-color-engaged: transparent;
  --link-underline-color: currentColor;
  --link-underline-height: 0.08em;
  --link-underline-offset: 0.2em;
}

.link {
  color: var(--link-color);
  font-weight: var(--link-font-weight);

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus)) {
    --link-color: var(--link-color-engaged);
    --link-icon-color: var(--link-icon-color-engaged);
  }
}

.link--block {
  display: flex;
}

.link__text {
  --underline-color: var(--link-underline-color);
  --underline-color-engaged: var(--link-underline-color-engaged);
  --underline-height: var(--link-underline-height);
  --underline-offset: var(--link-underline-offset);
  --underline-transition-duration: var(--duration-default);

  flex-grow: 1;
}

.link__icon {
  color: var(--link-icon-color);
  flex-shrink: 0;
  font-size: var(--link-icon-size);
  margin-inline-end: var(--link-icon-gap);
  margin-inline-start: var(--link-icon-puffer);
  transition-property: color;
  vertical-align: var(--link-icon-vertical-align);
  white-space: nowrap;
}
