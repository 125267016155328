:root {
  --pagination-font-size: 1.6rem;
  --pagination-color: var(--meta-text-color);
}

.pagination {
  align-items: center;
  color: var(--pagination-color);
  display: flex;
  font-size: var(--pagination-font-size);
  justify-content: space-between;
}

.pagination__buttons {
  display: flex;
  gap: 2rem;
}
