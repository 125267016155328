:root {
  --paragraph-color: inherit;
  --paragraph-size: inherit;
  --paragraph-line-height: var(--line-height-body);
  --paragraph-weight: inherit;
}

.paragraph {
  color: var(--paragraph-color);
  font-size: var(--paragraph-size);
  font-weight: var(--paragraph-weight);
  line-height: var(--paragraph-line-height);
}
