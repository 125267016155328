.site {
  --stage-min-height: var(--header-height);
  --stage-padding-block-start: calc(var(--stage-padding-block) + var(--header-height) / 2);

  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.site__header {
  --header-background-image: none;

  inset-block-start: 0;
  inset-inline: 0;
  position: absolute;
  z-index: z-index('header');
}

.site__footer {
  margin-block-start: auto;
}
