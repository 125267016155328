:root {
  --input-background-color: var(--color-white);
  --input-border-color-disabled: var(--text-color);
  --input-border-color: var(--form-color);
  --input-border-radius: var(--form-border-radius);
  --input-border-width: 2px;
  --input-color-disabled: var(--text-color);
  --input-color: var(--form-color);
  --input-dropdown-background-selected: var(--background-color);
  --input-dropdown-background: var(--light-background-color);
  --input-dropdown-border-color: var(--color-gray-900);
  --input-dropdown-color: var(--form-color);
  --input-dropdown-padding-block: 1.2rem;
  --input-focus-outline-color: var(--input-border-color);
  --input-focus-outline-offset: 0;
  --input-font-size: 1.8rem;
  --input-icon-color: var(--form-color);
  --input-icon-gap: 0.6rem;
  --input-icon-size: 1.6rem;
  --input-limit-background-over-limit: var(--error-color);
  --input-limit-background: var(--form-color);
  --input-limit-color-over-limit: var(--color-white);
  --input-limit-color: var(--color-white);
  --input-limit-size: 1.3rem;
  --input-line-height: 2.4rem;
  --input-padding-block: 1.2rem;
  --input-padding-inline: 2rem;
  --input-placeholder-color: var(--form-placeholder-color);
  --input-richtext-button-background-color-engaged: var(--light-background-color);
  --input-richtext-button-background-color-selected: var(--form-color);
  --input-richtext-button-background-color: var(--color-white);
  --input-richtext-button-border-color-engaged: var(--form-color);
  --input-richtext-button-border-color-selected: var(--form-color);
  --input-richtext-button-border-color: var(--color-gray-300);
  --input-richtext-button-color-engaged: var(--text-color);
  --input-richtext-button-color-selected: var(--color-white);
  --input-richtext-button-color: var(--color-gray-500);
  --input-richtext-button-size: 2.6rem;
  --input-tag-background: var(--background-color);
  --input-tag-remove-background: var(--color-gray-900);
  --input-tag-remove-color: var(--color-white);
  --input-uploads-background-color: var(--background-color);
  --input-uploads-color: var(--text-color);
  --input-uploads-gap: 1.5rem;
  --input-uploads-margin-block: 1.2rem;
}

.input {
  font-size: var(--input-font-size);
}

.input__contents {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input__input {
  --focus-outline-offset: var(--input-focus-outline-offset);
  --focus-outline-color: var(--input-focus-outline-color);
  --input-padding-inline-start-icon: calc(var(--input-padding-inline) + var(--input-icon-gap) + var(--input-icon-size));
  --selection-background-color: var(--input-color);
  --selection-foreground-color: var(--input-background-color);

  background-color: var(--input-background-color);
  border: var(--input-border-width) solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  color: var(--input-color);
  display: block;
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  min-height: calc(var(--input-line-height) + (var(--input-padding-block) + var(--input-border-width)) * 2);
  min-width: 100%;
  padding-block: var(--input-padding-block);
  padding-inline: var(--input-padding-inline);
  position: relative;
  text-align: left;
  transition-property: border-color;
  width: 100%;
  z-index: 2;

  &:where(:not(:first-child)) {
    padding-inline-start: var(--input-padding-inline-start-icon);
  }

  &:where(:not(:last-child)) {
    padding-inline-end: var(--input-padding-inline-start-icon);
  }

  &[disabled] {
    border-color: var(--input-color-disabled);
    color: var(--input-color-disabled);
    opacity: 0.5;
    user-select: none;
  }

  &::placeholder {
    color: var(--input-placeholder-color);
    opacity: 1;
  }

  &::file-selector-button {
    appearance: none;
    background-color: var(--input-file-selector-button-background-color, var(--form-color));
    border: 0;
    color: var(--input-file-selector-button-color, var(--color-white));
    cursor: pointer;
    font-family: inherit;
    font-size: var(--input-file-selector-button-font-size, 1.6rem);
    font-weight: var(--input-file-selector-button-font-weight, var(--font-weight-extrabold));
    line-height: var(--input-file-selector-button-line-height, 1.4);
    margin: 0;
    margin-inline-end: 1.5rem;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    transition-property: background-color;
  }

  &::file-selector-button:hover {
    background-color: var(--input-file-selector-button-background-color--interacted, var(--interactive-hover-color));
  }
}

.input__input--richtext {
  padding-inline: var(--input-padding-inline);

  &:focus-within {
    @include use-focus-outline();
  }
}

.input__input--color {
  cursor: pointer;
  height: var(--input-line-height);
  line-height: 0;

  &::-webkit-color-swatch,
  &::-moz-color-swatch {
    border: 0;
  }
}

.input__input--select {
  cursor: pointer;

  &:invalid {
    color: var(--input-placeholder-color);
  }
}

.input__input--tags {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-inline: var(--input-padding-inline);

  &:focus-within {
    @include use-focus-outline();
  }
}

.input__richtext-toolbar {
  display: flex;
  gap: 0.5rem;
  user-select: none;
}

.input__richtext-toolbar-button {
  background-color: var(--input-richtext-button-background-color);
  border: 1px solid var(--input-richtext-button-border-color);
  border-radius: 0.5rem;
  color: var(--input-richtext-button-color);
  font-size: calc(var(--input-richtext-button-size) * 0.5);
  height: var(--input-richtext-button-size);
  line-height: 0;
  transition-property: background-color, border-color, color;
  width: var(--input-richtext-button-size);

  &:is(:hover, :focus-visible) {
    background-color: var(--input-richtext-button-background-color-engaged);
    border-color: var(--input-richtext-button-border-color-engaged);
    color: var(--input-richtext-button-color-engaged);
  }
}

.input__richtext-toolbar-button--selected {
  background-color: var(--input-richtext-button-background-color-selected);
  border-color: var(--input-richtext-button-border-color-selected);
  color: var(--input-richtext-button-color-selected);
}

.input__richtext-toolbar-button-icon {
  pointer-events: none;
}

.input__richtext-content {
  --focus-outline-width: 0;

  color: var(--text-color);
  line-height: var(--line-height-body);
  margin-block-start: 1rem;

  > * + * {
    margin-block-start: 0.5rem;
  }

  :is(h1) {
    font-size: 1.3em;
    font-weight: bold;
  }

  :is(h2) {
    font-size: 1.1em;
    font-weight: bold;
  }

  :is(blockquote) {
    border-inline-start: 0.5rem solid var(--color-gray-500);
    padding-inline-start: 1.5rem;
  }

  :is(ul, ol) {
    padding-inline-start: 2rem;
  }

  :is(pre) {
    background-color: var(--light-background-color);
    font-family: monospace;
    white-space: nowrap;
  }

  :is(hr) {
    border-block-start: 1px solid var(--text-color);
  }

  :is(a) {
    color: var(--link-color);
    text-decoration-color: var(--link-color);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
  }
}

.input__option,
.input__option-group {
  color: var(--input-color);
  font-family: inherit;
}

.input__option--select {
  color: var(--input-placeholder-color);
}

.input__icon {
  color: var(--input-icon-color);
  font-size: var(--input-icon-size);
  inset-block-start: 50%;
  inset-inline-start: var(--input-padding-inline);
  line-height: 0;
  pointer-events: none;
  position: absolute;
  transform: translateY(-50%);
  transition-property: color;
  z-index: 3;
}

.input__icon--select {
  inset-inline: auto var(--input-padding-inline);
}

.input__limit {
  align-self: flex-end;
  background-color: var(--input-limit-background);
  color: var(--input-limit-color);
  display: inline-block;
  font-size: var(--input-limit-size);
  line-height: 1;
  padding-block: 0.4em;
  padding-inline: 0.8em;
  position: relative;
  transition-property: background-color, color;
  z-index: 1;

  .input__input[aria-invalid='true'] + & {
    background-color: var(--input-limit-background-over-limit);
    color: var(--input-limit-color-over-limit);
  }
}

.input__tags-input {
  --focus-outline-width: 0;

  display: inline-block;
  flex-grow: 1;
  min-width: 11rem;
  white-space: pre-wrap;
}

.input__tag {
  align-items: center;
  background-color: var(--input-tag-background);
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.4rem;
  gap: 0.3rem;
  line-height: 2.4rem;
  padding-inline: 0.5rem;
}

.input__tag-remove {
  cursor: pointer;
  display: block;
  height: 2rem;
  order: 2;
  position: relative;
  transition-property: scale;
  width: 2rem;

  &:is(:hover, :focus) {
    scale: 1.3;
  }

  &::after {
    background-color: var(--input-tag-remove-background);
    border-radius: 50%;
    color: var(--input-tag-remove-color);
    content: 'X';
    font-size: 1rem;
    font-weight: bold;
    inset: 0.4rem;
    line-height: 1.2rem;
    position: absolute;
    text-align: center;
  }
}

.input__dropdown {
  --input-tags-arrow-size: 1.2rem;
  --input-tags-arrow-offset: calc(var(--input-padding-inline) - var(--input-tags-arrow-size) / 2);

  animation-duration: var(--duration-default);
  animation-name: opacity;
  background-color: var(--input-dropdown-background);
  border-color: var(--input-dropdown-border-color);
  border-radius: var(--input-border-radius);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  color: var(--input-dropdown-color);
  font-size: 1.4rem;
  line-height: var(--line-height-small);
  position: absolute;
  text-align: left;
  transform: translateY(calc(var(--input-tags-arrow-size) / 2));
  width: 100%;
  z-index: 10;

  &::before {
    border: var(--input-tags-arrow-size) solid transparent;
    border-block-end-color: var(--input-dropdown-border-color);
    content: '';
    height: 0;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: translate(var(--input-tags-arrow-offset), -100%);
    width: 0;
    z-index: 2;
  }
}

.input__dropdown-item {
  padding-block: var(--input-dropdown-padding-block);
  padding-inline: var(--input-padding-inline);
  position: relative;
  transition-property: background-color, font-weight;

  & + & {
    border-top: 1px solid var(--input-dropdown-border-color);
  }

  &:first-child {
    border-start-end-radius: var(--input-border-radius);
    border-start-start-radius: var(--input-border-radius);
  }

  &:first-child::before {
    border: calc(var(--input-tags-arrow-size) - 0.15rem) solid transparent;
    border-block-end-color: var(--input-dropdown-background);
    content: '';
    height: 0;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    transform: translate(calc(var(--input-tags-arrow-offset) + 0.15rem), -100%);
    transition-property: border-color;
    width: 0;
    z-index: 4;
  }

  &:last-child {
    border-end-end-radius: var(--input-border-radius);
    border-end-start-radius: var(--input-border-radius);
  }
}

.input__dropdown-item--selected {
  background-color: var(--input-dropdown-background-selected);
  font-weight: bold;
  text-decoration: underline;

  &:first-child::before {
    border-block-end-color: var(--input-dropdown-background-selected);
  }
}

.input__uploads {
  display: flex;
  flex-wrap: wrap;
  gap: var(--input-uploads-gap);
  margin-block-start: var(--input-uploads-margin-block);
}

.input__upload {
  align-items: center;
  background-color: var(--input-uploads-background-color);
  border-radius: 0.5rem;
  color: var(--input-uploads-color);
  display: flex;
  font-size: 1.4rem;
  gap: 0.3rem;
  line-height: 2.4rem;
  padding-inline: 0.5rem;
}

:is(.iti__dropdown-content) {
  z-index: z-index('overlay');
}
