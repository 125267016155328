:root {
  @include use-responsive-sizing(--embed-wall-padding-block --embed-wall-padding-inline, responsive-map(xs 2rem, xl 4rem));

  --embed-content-padding-block: 0;
  --embed-content-padding-inline: 0;
  --embed-wall-background-color: var(--color-red-100);
  --embed-wall-button-margin-block: 3rem;
  --embed-wall-font-size: inherit;
}

.embed {
  iframe {
    height: revert-layer;
  }
}

.embed__wall {
  background-color: var(--embed-wall-background-color);
  font-size: var(--embed-wall-font-size);
  line-height: var(--line-height-body);
  padding-block: var(--embed-wall-padding-block);
  padding-inline: var(--embed-wall-padding-inline);
  text-align: center;
}

.embed__message {
  margin-block-end: var(--embed-wall-button-margin-block);
  margin-inline: auto;
  max-width: 60ch;
}

.embed__content {
  padding-block: var(--embed-content-padding-block);
  padding-inline: var(--embed-content-padding-inline);
}
