:root {
  @include use-responsive-sizing(--tag-font-size, responsive-map(xs 1.4rem, xl 1.6rem));

  --tag-background-color-engaged: var(--color-white);
  --tag-background-color: var(--secondary-color);
  --tag-border-size: 3px;
  --tag-color-engaged: var(--secondary-color);
  --tag-color: var(--color-white);
  --tag-font-weight: var(--font-weight-bold);
  --tag-line-height: var(--line-height-small);
  --tag-padding-block: 0.25em;
  --tag-padding-inline: 0.5em;
}

.tag {
  --underline-color-engaged: transparent;
  --underline-height: 0;

  background-color: var(--tag-background-color);
  border: var(--tag-border-size) solid var(--tag-background-color);
  color: var(--tag-color);
  display: inline-block;
  font-size: var(--tag-font-size);
  font-weight: var(--tag-font-weight);
  line-height: var(--tag-line-height);
  padding-block: var(--tag-padding-block);
  padding-inline: var(--tag-padding-inline);
  text-transform: uppercase;
  transition-duration: var(--duration-fast);
  transition-property: background-color, color;

  &:any-link:is(:hover, :focus-visible) {
    background-color: var(--tag-background-color-engaged);
    color: var(--tag-color-engaged);
  }
}
