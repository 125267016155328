:root {
  --checkbox-background-color-checked: var(--form-color);
  --checkbox-background-color: var(--color-white);
  --checkbox-border-color-disabled: var(--text-color);
  --checkbox-border-color: var(--form-color);
  --checkbox-border-radius: var(--border-radius-default);
  --checkbox-border-width: 2px;
  --checkbox-font-size: var(--font-size-default);
  --checkbox-gap: 1.5rem;
  --checkbox-icon-color: var(--color-white);
  --checkbox-line-height: 2.8rem;
  --checkbox-required-color: var(--required-color);
  --checkbox-size: 2.8rem;
  --checkbox-text-color: var(--text-color);
}

.checkbox {
  cursor: pointer;
  display: flex;
  gap: var(--checkbox-gap);
  position: relative;
}

.checkbox--disabled {
  cursor: default;
  opacity: 0.5;
}

.checkbox__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.checkbox__icon {
  --focus-outline-color: var(--checkbox-border-color);

  align-items: center;
  background-color: var(--checkbox-background-color);
  border: var(--checkbox-border-width) solid var(--checkbox-border-color);
  border-radius: var(--checkbox-border-radius);
  color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: calc(var(--checkbox-size) / 2);
  height: var(--checkbox-size);
  justify-content: center;
  transition-property: background-color, color;
  user-select: none;
  width: var(--checkbox-size);

  .checkbox__input:checked ~ & {
    background-color: var(--checkbox-background-color-checked);
    border-color: var(--checkbox-border-color);
    color: var(--checkbox-icon-color);
  }

  .checkbox__input[disabled] ~ & {
    border-color: var(--checkbox-border-color-disabled);
  }

  .checkbox__input:focus-visible ~ & {
    @include use-focus-outline();
  }
}

.checkbox__label {
  color: var(--checkbox-text-color);
  font-size: var(--checkbox-font-size);
  line-height: var(--checkbox-line-height);
  margin-block-start: calc((var(--checkbox-size) - var(--checkbox-line-height)) / 2);
  text-align: left;
}

.checkbox__required {
  color: var(--checkbox-required-color);
  cursor: help;
  display: inline-block;
  font-size: 1.4em;
  font-weight: var(--font-weight-extrabold);
  height: 0.7em;
  line-height: 1;
  margin-inline-start: 0.5rem;
  overflow: hidden;
  vertical-align: middle;
}
