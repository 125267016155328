:root {
  @include use-responsive-sizing(--breadcrumb-font-size, responsive-map(xs 1.3rem, xl 1.6rem));

  --breadcrumb-text-color: var(--meta-text-color);
  --breadcrumb-icon-color: var(--color-gray-600);
  --breadcrumb-arrow-color: var(--color-gray-400);
}

.breadcrumb {
  --focus-outline-offset: 5px;
}

.breadcrumb__list {
  color: var(--breadcrumb-text-color);
  font-size: var(--breadcrumb-font-size);
  line-height: 1.5;
  list-style: none;
  padding-block-end: 0.4em;
}

.breadcrumb__item {
  display: inline;

  @include mq($until: m) {
    &:not(:nth-last-child(2)) {
      display: none;
    }
  }
}

.breadcrumb__arrow {
  color: var(--breadcrumb-arrow-color);
  font-size: 0.8em;
  margin-inline: 1em;
  vertical-align: -0.1em;

  @include mq($until: m) {
    display: none;
  }
}

.breadcrumb__link {
  &:is([aria-current='page']) {
    font-weight: var(--font-weight-bold);
  }
}

.breadcrumb__icon {
  color: var(--breadcrumb-icon-color);
  font-size: 1.2em;
  margin-inline-end: 0.5em;
  vertical-align: 0.1em;

  @include mq($from: m) {
    .breadcrumb__item:not(:first-child) & {
      display: none;
    }
  }
}
