:root {
  @include use-responsive-sizing(--text-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--text-box-padding-block --text-box-padding-inline, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--text-box-button-font-size, responsive-map(xs 1.6rem, xl 2rem));

  --text-box-background-color: var(--color-red-100);
  --text-box-button-margin-block: 2.5rem;
  --text-box-content-margin-block: 1rem;
  --text-box-headline-color: var(--headline-color);
  --text-box-headline-margin-block: 1.5rem;
  --text-box-image-margin-block: 2.5rem;
  --text-box-image-offset: 3rem;
  --text-box-image-width: clamp(20rem, 80%, 50rem);
  --text-box-line-height: var(--line-height-body);
}

.text-box--with-image {
  container-name: text-box;
  container-type: inline-size;
  padding-block-start: var(--podcast-box-image-offset);
}

.text-box__inner {
  background-color: var(--text-box-background-color);
  padding-block: var(--text-box-padding-block);
  padding-inline: var(--text-box-padding-inline);
  text-align: left;

  > :not(.text-box__image):first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.text-box__image {
  --image-z-index: 1;

  margin-block-end: var(--text-box-image-margin-block);
  margin-block-start: calc((var(--text-box-padding-block) + var(--podcast-box-image-offset)) * -1);
  position: relative;
  width: var(--text-box-image-width);
}

.text-box__headline {
  --headline-color: var(--text-box-headline-color);

  margin-block: var(--text-box-headline-margin-block);
}

.text-box__copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  position: absolute;
  z-index: 2;
}

.text-box__text {
  font-size: var(--text-box-font-size);
  line-height: var(--text-box-line-height);
  margin-block: var(--text-box-content-margin-block);

  > * + * {
    margin-block-start: 0.5em;
  }
}

.text-box__button {
  --button-font-size: var(--text-box-button-font-size);

  margin-block: var(--text-box-button-margin-block);
}
