:root {
  @include use-responsive-sizing(--details-item-padding-block, responsive-map(xs 1.5rem, xl 3rem));
  @include use-responsive-sizing(--details-item-padding-inline, responsive-map(xs 1.5rem, xl 4rem));

  --details-background-color: var(--color-red-100);
  --details-border-style: solid;
  --details-border-width: 1px;
  --details-border-color: var(--color-red-500);
  --details-font-size: inherit;
  --details-item-label-width: 25rem;
}

.details {
  border-color: var(--details-border-color);
  border-style: var(--details-border-style);
  border-width: var(--details-border-width);
  position: relative;
}

.details__items {
  font-size: var(--details-font-size);
}

.details__item {
  & + & {
    border-block-start: 1px solid var(--details-border-color);
  }

  @include mq($from: m) {
    display: grid;
    grid-template-columns: var(--details-item-label-width) auto;
    position: relative;
  }
}

.details__item-label {
  background-color: var(--details-background-color);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-body);
  padding-block: var(--details-item-padding-block);
  padding-inline: var(--details-item-padding-inline);

  @include mq($from: m) {
    flex-shrink: 0;
  }
}

.details__item-text {
  --bullet-list-indent: -0.5rem;

  line-height: var(--line-height-body);
  padding-block: var(--details-item-padding-block);
  padding-inline: var(--details-item-padding-inline);

  * + * {
    margin-top: 0.5em;
  }
}
