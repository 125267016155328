:root {
  --external-video-aspect-ratio: 16 / 9;
  --external-video-background-color: var(--light-background-color);
  --external-video-play-background-color-hover: var(--interactive-hover-color);
  --external-video-play-background-color: var(--interactive-color);
  --external-video-play-color: var(--color-white);
  --external-video-play-size: 0.15;
  --external-video-warning-color: var(--color-white);
  --external-video-z-index: #{z-index('default')};
}

.external-video {
  aspect-ratio: var(--external-video-aspect-ratio);
  container-name: external-video;
  container-type: inline-size;
  position: relative;
  z-index: var(--external-video-z-index);
}

.external-video__preview {
  --external-video-image-scale: 1.1;

  aspect-ratio: var(--external-video-aspect-ratio);
  background-color: var(--external-video-background-color);
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:is(:hover, :focus-visible) {
    --external-video-image-scale: 1.2;
    --external-video-play-background-color: var(--external-video-play-background-color-hover);
  }

  &::after {
    @include use-scrim-gradient($start-color: rgba(0, 0, 0, 0.8), $direction: 'to top');

    content: '';
    inset: 0;
    position: absolute;
    z-index: 2;
  }
}

.external-video__preview-image {
  filter: blur(0.5rem);
  height: 100%;
  inset: 0;
  object-fit: cover;
  position: absolute;
  transform: scale(var(--external-video-image-scale));
  transition-property: transform;
  width: 100%;
  z-index: 1;
}

.external-video__preview-icon {
  align-items: center;
  background-color: var(--external-video-play-background-color);
  border-radius: 50%;
  color: var(--external-video-play-color);
  display: flex;
  font-size: calc((var(--external-video-play-size) / 2) * 100cqw);
  height: calc(var(--external-video-play-size) * 100cqw);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  justify-content: center;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  transition-property: background-color;
  width: calc(var(--external-video-play-size) * 100cqw);
  z-index: 3;
}

.external-video__preview-warning {
  color: var(--external-video-warning-color);
  font-size: 1.2rem;
  inset-block-end: 2rem;
  line-height: var(--line-height-small);
  padding-inline: 2rem;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 3;
}

.external-video__iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
