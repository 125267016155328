:root {
  @include use-responsive-sizing(--buttons-font-size, responsive-map(xs 1.6rem, xl 2rem));

  --buttons-padding-block: 0;
  --buttons-padding-inline: 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  padding-block: var(--buttons-padding-block);
  padding-inline: var(--buttons-padding-inline);
}

.buttons--horizontal {
  flex-direction: row;
}

.buttons--centered {
  justify-content: center;
  text-align: center;
}

.buttons__button {
  --button-font-size: var(--buttons-font-size);

  align-items: center;
  display: flex;
}
