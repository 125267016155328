:root {
  --copyright-background-color: var(--color-gray-900);
  --copyright-color: var(--color-white);
  --copyright-icon-size: 1.8rem;
  --copyright-size: 3rem;
  --copyright-tooltip-background-color: var(--color-gray-900);
  --copyright-tooltip-color: var(--color-white);
  --copyright-z-index: #{z-index('default')};
}

.copyright {
  display: inline-flex;
  font-size: var(--copyright-size);
  position: relative;
  z-index: var(--copyright-z-index);
}

.copyright__button {
  align-items: center;
  background-color: var(--copyright-background-color);
  border-radius: 50%;
  color: var(--copyright-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--copyright-icon-size);
  height: var(--copyright-size);
  justify-content: center;
  line-height: 0;
  opacity: 0.4;
  transition-duration: var(--duration-default);
  transition-property: opacity;
  user-select: none;
  width: var(--copyright-size);
  z-index: 1;

  &:is(:hover, :focus-visible, .copyright__button--active) {
    opacity: 1;
  }
}

.copyright__tooltip {
  --tooltip-background-color: var(--copyright-tooltip-background-color);
  --tooltip-color: var(--copyright-tooltip-color);
}
