:root {
  --bullet-list-indent: 0.5em;
  --bullet-list-marker-color: var(--primary-color);
  --bullet-list-marker-font-weight: var(--font-weight-bold);
  --bullet-list-spacing: 0.5em;
}

.bullet-list {
  list-style-type: var(--bullet-list-marker);
  overflow: hidden;
  padding-inline-start: calc(var(--bullet-list-indent) + 1.4em);

  & & {
    margin-top: var(--bullet-list-spacing);
  }
}

.bullet-list--unordered {
  --bullet-list-marker: '– ';
  --bullet-list-marker-size: 1.4em;
  --bullet-list-marker-space: 0.5em;
}

.bullet-list--ordered {
  --bullet-list-marker: decimal;
  --bullet-list-marker-size: 1em;
  --bullet-list-marker-space: 0.5em;
}

.bullet-list__item {
  padding-inline-start: var(--bullet-list-marker-space);
  position: relative;

  & + & {
    margin-block-start: var(--bullet-list-spacing);
  }

  &::marker {
    color: var(--bullet-list-marker-color);
    font-size: var(--bullet-list-marker-size);
    font-weight: var(--bullet-list-marker-font-weight);
    line-height: 0;
  }
}
