html {
  color: var(--text-color);
  font-family: var(--root-font-family);
  font-optical-sizing: none;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-stretch: var(--root-font-stretch);
  font-synthesis-weight: none;
  font-variation-settings: 'wdth' 100;
  font-weight: var(--root-font-weight);
  height: auto;
  letter-spacing: 0;
  line-height: 1;
  min-height: 100%;
  min-width: var(--root-min-width);
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}
