:root {
  @include use-responsive-sizing(--document-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--document-box-padding-block --document-box-padding-inline, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--document-box-button-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--document-box-image-offset, responsive-map(xs 3rem, xl 5rem));

  --document-box-background-color: var(--color-red-100);
  --document-box-link-gap: 0.5rem;
  --document-box-gap: 1rem;
  --document-box-image-gap: 4rem;
  --document-box-icon-gap: 1rem;
  --document-box-image-offset: 3rem;
}

.document-box {
  container-name: document-box;
  container-type: inline-size;
  padding-block-start: var(--document-box-image-offset);
}

.document-box__inner {
  background-color: var(--document-box-background-color);
  column-gap: var(--document-box-image-gap);
  display: flex;
  flex-direction: column;
  font-size: var(--document-box-font-size);
  height: 100%;
  line-height: var(--line-height-body);
  padding-block: var(--document-box-padding-block);
  padding-inline: var(--document-box-padding-inline);
  row-gap: calc(var(--document-box-padding-block) * 1.5);

  @container document-box (min-width: 60rem) {
    flex-direction: row;
  }
}

.document-box__image {
  flex-shrink: 0;
  margin-block-start: calc(var(--document-box-image-offset) * -1 - var(--document-box-padding-block));
  max-width: 30rem;
  min-width: 20rem;
  width: 33.33%;
}

.document-box__content {
  display: flex;
  flex-direction: column;
  gap: var(--document-box-gap);
}

.document-box__button {
  --button-font-size: var(--document-box-button-font-size);

  &:not(:first-child) {
    margin-block-start: auto;
    padding-block: var(--document-box-gap);
  }
}

.document-box__link {
  --link-icon-gap: var(--document-box-icon-gap);
  --link-underline-puffer: 0;
  --link-icon-size: 1em;
  --link-icon-vertical-align: 0;

  display: block;

  & + & {
    margin-block-start: var(--document-box-link-gap);
  }
}
