:root {
  @include use-responsive-sizing(--list-teasers-gap, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(--list-teasers-search-margin-block, responsive-map(xs 3rem, xl 6rem));
  @include use-responsive-sizing(--list-teasers-pagination-margin-block, responsive-map(xs 3rem, xl 6rem));

  --list-teasers-pagination-padding-inline: 0;
}

.list-teasers__search {
  background-color: var(--color-red-100);
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-block-end: var(--list-teasers-search-margin-block);
  padding: 2rem;
}

.list-teasers__search-input {
  flex-grow: 1;
}

.list-teasers__search-button {
  flex-shrink: 0;
}

.list-teasers__list-teaser {
  & + & {
    margin-block-start: var(--list-teasers-gap);
  }
}

.list-teasers__pagination {
  margin-block-start: var(--list-teasers-pagination-margin-block);
  padding-inline: var(--list-teasers-pagination-padding-inline);
}
