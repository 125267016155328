:root {
  --people-padding-inline: 0;
  --people-padding-block: 0;
}

.people__inner {
  @include use-break-out();
}

.people__people {
  @include use-responsive-sizing(gap, $gaps);

  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-block: var(--people-padding-block);
  padding-inline: var(--people-padding-inline);
  position: relative;
  scroll-behavior: smooth;
  scroll-padding-inline: var(--people-padding-inline);
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
}

.people__people--was-dragged {
  scroll-behavior: auto;
}

.people__person {
  @include use-responsive-sizing(width, (
    xs: min(80%, 30rem),
    m: column-width(m, 6),
    l: column-width(l, 4),
    xl: column-width(xl, 4),
  ));

  --contact-box-background-color: transparent;
  --contact-box-padding-block: 0;
  --contact-box-padding-inline: 0;
  --contact-box-image: 100%;

  flex-shrink: 0;
  scroll-snap-align: start;

  .people__people--was-dragged & {
    scroll-snap-align: none;
  }
}

.people--small .people__person {
  @include use-responsive-sizing(width, (
    m: column-width(m, 4),
    l: column-width(l, 4),
    xl: column-width(xl, 3),
  ));
}

.people__navigation {
  align-items: center;
  display: flex;
  gap: 2rem;
  margin-block-start: 5rem;
  padding-block: var(--people-padding-block);
  padding-inline: var(--people-padding-inline);
}

.people__scroll-bar {
  flex-grow: 1;
  height: 1.8rem;
  margin-inline-end: 2rem;
  opacity: 0.3;
  pointer-events: none;
  position: relative;
  transition-property: opacity, transform;
}

.people__scroll-bar--enabled {
  cursor: pointer;
  opacity: 0.5;
  pointer-events: all;

  &:hover {
    opacity: 1;
  }
}

.people__scroll-bar-backdrop {
  background-color: var(--color-gray-200);
  inset: 0;
  opacity: 0.2;
  position: absolute;
}

.people__scroll-bar-thumb {
  background-color: var(--color-gray-300);
  inset-block: 0;
  position: absolute;
  will-change: left, width, transform;
}

.people__buttons {
  display: flex;
  flex-shrink: 0;
  gap: 2rem;
}
