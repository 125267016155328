:root {
  @include use-responsive-sizing(--stage-padding-block, responsive-map(xs 4rem, xl 8rem));
  @include use-responsive-sizing(--stage-content-padding-block, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(--stage-text-gap, responsive-map(xs 1rem, xl 2rem));
  @include use-responsive-sizing(--stage-text-size, responsive-map(xs 2rem, xl 2.8rem));

  --stage-background-image: var(--primary-gradient);
  --stage-button-gap: 3rem;
  --stage-color: var(--color-white);
  --stage-min-height: 0;
  --stage-padding-block-end: var(--stage-padding-block);
  --stage-padding-block-start: var(--stage-padding-block);
  --stage-z-index: #{z-index('default')};
}

.stage {
  background-image: var(--stage-background-image);
  color: var(--stage-color);
  z-index: var(--stage-z-index);
}

.stage__inner {
  @include use-container();

  min-height: var(--stage-min-height);
  padding-block-end: var(--stage-padding-block-end);
  padding-block-start: var(--stage-padding-block-start);
  position: relative;

  &:empty {
    padding-block: 0;
  }
}

.stage__headline {
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: min(100%, 55rem),
    m: column-width(m, 8),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  --headline-color: var(--stage-color);
  --headline-line-height: var(--line-height-small);
  --headline-font-weight: var(--font-weight-extrabold);
  --headline-font-size: 3.6rem;

  position: relative;
  z-index: 2;

  @include mq($from: s) {
    --headline-font-size: calc(4vw + 1rem);
  }

  @include mq($from: rem2px($inner-container-max-width)) {
    --headline-font-size: calc(#{$inner-container-max-width * 0.04} + 1rem);
  }
}

.stage__text {
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: min(100%, 55rem),
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  --link-color: var(--stage-color);
  --link-color-engaged: var(--stage-color);

  font-size: var(--stage-text-size);
  line-height: var(--line-height-body);
  position: relative;
  z-index: 2;

  .stage--image & {
    @include use-responsive-sizing(width, (
      m: column-width(m, 8),
      l: column-width(l, 6),
      xl: column-width(xl, 6),
    ));
  }

  &:not(:first-child) {
    margin-block-start: var(--stage-text-gap);
  }
}

.stage__image {
  --stage-image-height: 100%;
  --stage-image-width: 80%;
  --stage-image-max-width: 30rem;
  --stage-image-spillover: 3rem;

  height: calc(var(--stage-image-height) + var(--stage-image-spillover));
  inset-block-end: calc(var(--stage-image-spillover) * -1);
  inset-inline-end: 0;
  max-width: var(--stage-image-max-width);
  object-fit: cover;
  object-position: 0% 100%;
  pointer-events: none;
  position: absolute;
  user-select: none;
  width: var(--stage-image-width);
  z-index: 1;

  @include mq($from: s) {
    --stage-image-height: 120%;
    --stage-image-width: 75%;
    --stage-image-max-width: 40rem;
  }

  @include mq($from: m) {
    --stage-image-spillover: 4rem;
    --stage-image-height: 140%;
    --stage-image-max-width: 50rem;
  }

  @include mq($from: l) {
    --stage-image-max-width: 60rem;
  }

  @include mq($from: xl) {
    --stage-image-max-width: 80rem;
  }

  @include mq($from: rem2px($inner-container-max-width)) {
    height: calc(var(--inner-container-padding) + var(--stage-image-max-width));
    inset-inline-end: calc(var(--inner-container-padding) * -1);
    max-width: calc(var(--inner-container-padding) + var(--stage-image-max-width));
    width: calc(var(--inner-container-padding) + var(--stage-image-max-width));
  }
}

.stage__content {
  padding-block: var(--stage-content-padding-block);
}

.stage__button {
  --icon-button-background-color: transparent;
  --icon-button-background-color-hover: transparent;
  --icon-button-icon-size: 1.4em;
  --icon-button-size: 1em;
  --icon-button-text-size: calc(var(--stage-text-size) * 0.8);
  --icon-button-text-color: var(--stage-color);

  position: relative;
  z-index: 2;

  &:not(:first-child) {
    margin-block-start: var(--stage-button-gap);
  }
}
