:root {
  @include use-responsive-sizing(--footer-padding-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-sizing(--footer-newsletter-padding, responsive-map(xs 2rem, xl 4rem));

  --footer-background-color: var(--color-gray-50);
  --footer-service-link-color: var(--meta-text-color);
  --footer-service-font-size: 1.6rem;
  --footer-service-gap: 2rem;
  --footer-social-media-gap: 2rem;
  --footer-column-gap: var(--gap);
  --footer-newsletter-background-image: var(--secondary-gradient);
  --footer-newsletter-color: var(--color-white);
}

.footer__newsletter {
  @include use-container();
}

.footer__newsletter-form {
  @include use-responsive-sizing(width, (
    m: column-width(m, 8, $number-of-columns: 12),
    l: column-width(l, 6, $number-of-columns: 12),
    xl: column-width(xl, 5, $number-of-columns: 12),
  ));

  background-image: var(--footer-newsletter-background-image);
  color: var(--footer-newsletter-color);
  margin-inline-start: auto;
  padding: var(--footer-newsletter-padding);
  position: relative;
  z-index: 2;
}

.footer__newsletter-headline {
  --headline-color: var(--footer-newsletter-color);
}

.footer__newsletter-input-button {
  display: flex;
  gap: 2rem;
  margin-block-start: 3rem;
}

.footer__newsletter-input {
  --input-border-color: var(--color-white);
  --input-focus-outline-offset: 0.2rem;
  --input-focus-outline-color: var(--color-white);

  flex-grow: 1;
}

.footer__newsletter-button {
  --icon-button-size: 5.2rem;
  --icon-button-border-color: var(--color-white);
  --icon-button-border-width: 3px;
  --focus-outline-color: var(--color-white);
}

.footer__inner {
  @include use-container();

  column-gap: var(--footer-column-gap);
  display: flex;
  flex-direction: column;
  padding-block: var(--footer-padding-block);
  position: relative;
  row-gap: var(--footer-padding-block);

  .footer__newsletter + & {
    margin-block-start: calc(var(--footer-padding-block) / -2.5 - 4rem);
    padding-block-start: calc(var(--footer-padding-block) + 4rem);

    @include mq($from: l) {
      margin-block-start: calc(var(--footer-padding-block) / -1.5 - 4rem);
    }

    @include mq($from: xl) {
      margin-block-start: calc(var(--footer-padding-block) / -1 - 4rem);
    }
  }

  &::before {
    background-color: var(--footer-background-color);
    content: '';
    inset-block: 0;
    inset-inline: calc((100vw - 100%) / -2);
    position: absolute;
    width: 100vw;
    z-index: 1;
  }

  @include mq($from: m) {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__social-media {
  position: relative;
  z-index: 2;
}

.footer__social-media-links {
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: var(--footer-social-media-gap);
  margin-block-start: calc(var(--footer-social-media-gap) * 0.75);
}

.footer__social-media-link {
  display: block;
}

.footer__service-links {
  --link-color: var(--footer-service-link-color);

  column-gap: var(--footer-service-gap);
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-size: var(--footer-service-font-size);
  justify-content: flex-end;
  line-height: var(--line-height-body);
  position: relative;
  row-gap: calc(var(--footer-service-gap) / 2);
  z-index: 2;
}

.footer__service-link {
  display: block;
}
