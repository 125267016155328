:root {
  @include use-responsive-sizing(--people-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--people-box-padding-block --people-box-padding-inline, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--people-box-image-size, responsive-map(xs 5rem, xl 8rem));

  --people-box-background-color: var(--color-red-100);
  --people-box-gap: 1.5rem;
}

.people-box {
  background-color: var(--people-box-background-color);
  display: flex;
  flex-direction: column;
  font-size: var(--people-box-font-size);
  gap: 2rem;
  line-height: var(--line-height-body);
  padding-block: var(--people-box-padding-block);
  padding-inline: var(--people-box-padding-inline);
}

.people-box__person {
  display: flex;
  gap: 2rem;

  & + & {
    margin-block-start: var(--people-box-gap);
  }
}

.people-box__person-image {
  flex-shrink: 0;
  height: var(--people-box-image-size);
  margin-block-start: 0.2rem;
  object-fit: cover;
  overflow: hidden;
  width: var(--people-box-image-size);
}
