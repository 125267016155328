$form-mobile-switch-breakpoint: m;

:root {
  --form-alert-margin-block: 4rem;
  --form-errors-background-color: var(--error-color);
  --form-errors-color: var(--color-white);
  --form-errors-font-size: 1.4rem;
  --form-errors-margin-block: 0.7rem;
  --form-errors-padding-block: 0.8rem;
  --form-errors-padding-inline: 1.2rem;
  --form-page-background-color: var(--color-white);
  --form-page-border-color: var(--background-color);
  --form-page-border-width: 3px;
  --form-page-buttons-gap: 4rem;
  --form-page-buttons-margin-block: 4rem;
  --form-page-fields-column-gap: var(--gap);
  --form-page-fields-row-gap: 2rem;
  --form-page-padding-block: 2rem;
  --form-page-padding-inline: 2rem;
  --form-page-title-color: var(--text-color);
  --form-page-title-font-size: 2rem;
  --form-page-title-font-weight: var(--font-weight-bold);
  --form-page-title-margin-block: 3rem;
  --form-progress-gap: 3rem;
  --form-tab-color: var(--text-color);
  --form-tab-current-font-weight: var(--font-weight-bold);
  --form-tab-font-size: 1.8rem;
  --form-tab-font-weight: var(--font-weight-regular);
  --form-tab-gap: 1rem;
  --form-tab-padding-block: 1.5rem;
  --form-tab-padding-inline: 2rem;
  --form-title-gap: 3rem;
}

.form,
.form__form,
.form__page-fields {
  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.form__alert {
  margin-block: var(--form-alert-margin-block);
}

.form__title {
  margin-block-end: var(--form-title-gap);
}

.form__progress {
  margin-block: var(--form-progress-gap);
}

.form__errors {
  --notice-color: var(--form-errors-color);
  --notice-icon-color: var(--form-errors-color);

  background-color: var(--form-errors-background-color);
  border-radius: var(--border-radius-default);
  font-size: var(--form-errors-font-size);
  margin-block: var(--form-errors-margin-block);
  padding-block: var(--form-errors-padding-block);
  padding-inline: var(--form-errors-padding-inline);
}

.form__tabs {
  display: flex;
  gap: var(--form-tab-gap);
  margin-block-end: calc(var(--form-page-border-width) * -1);
  position: relative;
  z-index: 2;

  @include mq($until: $form-mobile-switch-breakpoint) {
    display: none;
  }
}

.form__tab {
  background-color: var(--form-page-border-color);
  border: var(--form-page-border-width) solid var(--form-page-border-color);
  border-block-end: 0;
  color: var(--form-tab-color);
  display: block;
  font-size: var(--form-tab-font-size);
  font-weight: var(--form-tab-font-weight);
  padding-block-end: calc(var(--form-tab-padding-block) + var(--form-page-border-width));
  padding-block-start: var(--form-tab-padding-block);
  padding-inline: var(--form-tab-padding-inline);

  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__tab--current {
  background-color: var(--form-page-background-color);
  font-weight: var(--form-tab-current-font-weight);
}

.form__page-title {
  background-color: var(--form-page-border-color);
  border: var(--form-page-border-width) solid var(--form-page-border-color);
  color: var(--form-page-title-color);
  font-size: var(--form-page-title-font-size);
  font-weight: var(--form-page-title-font-weight);
  padding-block: var(--form-page-padding-block);
  padding-inline: var(--form-page-padding-inline);
  position: relative;
  z-index: 2;

  .form__page--current & {
    background-color: var(--form-page-background-color);
    border-block-end-width: 1px;
    margin-block-end: calc(var(--form-page-border-width) * -1);
  }

  @include mq($from: $form-mobile-switch-breakpoint) {
    display: none;
  }
}

.form__page-fields {
  appearance: none;
  background-color: var(--form-page-background-color);
  border: var(--form-page-border-width) solid var(--form-page-border-color);
  display: block;
  padding-block: var(--form-page-padding-block);
  padding-inline: var(--form-page-padding-inline);
  position: relative;
  z-index: 1;
}

.form__page-legend {
  @include use-clearfix();

  appearance: none;
  color: var(--form-page-title-color);
  display: block;
  float: left;
  font-size: var(--form-page-title-font-size);
  font-weight: var(--form-page-title-font-weight);
  margin-block: var(--form-page-title-margin-block);
  padding-inline: 0;
  width: 100%;

  @include mq($until: $form-mobile-switch-breakpoint) {
    display: none;
  }
}

.form__page-legend--hidden {
  @include mq($from: $form-mobile-switch-breakpoint) {
    @include use-hidden-visually();
  }
}

.form__page-fields--hidden {
  display: none;
}

.form__row {
  --field-groups-column-gap: var(--form-page-fields-column-gap);
  --field-groups-row-gap: var(--form-page-fields-row-gap);

  clear: both;
  display: grid;
  gap: var(--form-page-fields-column-gap);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  & + & {
    margin-block-start: var(--form-page-fields-row-gap);
  }

  &[hidden] {
    display: none;
  }
}

.form__field {
  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__page-buttons {
  align-items: center;
  display: flex;
  gap: var(--form-page-buttons-gap);
  margin-block: var(--form-page-buttons-margin-block);
}

.form__page-buttons--right {
  justify-content: flex-end;
}

.form__page-buttons--center-save-right,
.form__page-buttons--center-save-left {
  justify-content: center;
}

.form__page-button {
  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.form__page-button--save {
  .form__page-buttons--right-save-left &,
  .form__page-buttons--center-save-left & {
    order: -2;
  }

  .form__page-buttons--save-right & {
    margin-inline-start: auto;
  }

  .form__page-buttons--save-left & {
    margin-inline-end: auto;
    order: -2;
  }
}

.form__page-button--back {
  order: -1;
}
