:root {
  --button-background-color: var(--interactive-color);
  --button-background-color-hover: var(--interactive-hover-color);
  --button-color: var(--color-white);
  --button-font-weight: var(--font-weight-extrabold);
  --button-height: 2.6em;
  --button-font-size: 2rem;
  --button-icon-size: 1.2em;
  --button-line-height: 1.4;
  --button-padding-inline: 1em;
  --button-width: auto;
  --button-icon-gap: 1.75em;
}

.button {
  --button-text-height: calc(var(--button-font-size) * var(--button-line-height));
  --button-padding-block: calc((var(--button-height) - var(--button-text-height)) / 2);

  align-items: center;
  background-color: var(--button-background-color);
  color: var(--button-color);
  display: inline-flex;
  font-size: var(--button-font-size);
  gap: var(--button-icon-gap);
  justify-content: center;
  min-height: var(--button-height);
  padding-block: var(--button-padding-block);
  padding-inline: var(--button-padding-inline);
  transition-duration: var(--duration-fast);
  transition-property: background-color;
  user-select: none;
  width: var(--button-width);

  &:is(:hover:not([disabled]), :focus-visible:not(.has-invisible-focus), .button--active),
  .has-button:is(:hover, :focus-within) &:not([disabled]) {
    background-color: var(--button-background-color-hover);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.button__icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: var(--button-icon-size);
  justify-content: center;
  z-index: 2;
}

.button__text {
  font-weight: var(--button-font-weight);
  line-height: var(--button-line-height);
  position: relative;
  z-index: 1;

  .button__icon + &::before {
    background-color: rgba(255, 255, 255, 0.3);
    content: '';
    inset-block: 0;
    inset-inline-start: calc(var(--button-icon-gap) / -2);
    position: absolute;
    width: 1px;
    z-index: 1;
  }

  .button__icon + &::after {
    inset-inline-start: calc((var(--button-icon-gap) + var(--button-padding-inline) + var(--button-icon-size)) * -1);
  }
}

.button__appendix {
  display: inline-block;
  font-weight: var(--font-weight-regular);
  white-space: nowrap;
}
