@use 'sass:math';
@use 'sass:map';

// Root
$root-font-size-px: 10px;
$root-min-width: 360px;

// List of z-index levels
$z-index-list: (
  'tooltip',
  'overlay',
  'dropdown',
  'skip-link',
  'header',
  'default',
);

// Number of columns the grid system has
$columns: 12;

// Gaps between grid columns
$gaps: (
  xs: 2rem,
  s: 3rem,
  m: 3rem,
  l: 4rem,
  xl: 4rem,
);

// stylelint-disable length-zero-no-unit
// List of breakpoints
$mq-breakpoints: (
  xs: 0px,
  s: 512px,
  m: 768px,
  l: 1024px,
  xl: 1280px,
);
// stylelint-enable

// Inner container padding (acts like body padding)
$inner-container-padding: (
  xs: 5vw,
  s: 5vw,
  m: 5vw,
  l: 5vw,
  xl: 5vw,
);

// Max width of the inner container
$inner-container-max-width: 134rem;

// Calculated value of the minium and maximum padding of the inner container
$inner-container-min-padding: math.div(map.get($inner-container-padding, xs), 100vw) * $root-min-width;
$inner-container-max-padding: math.div(map.get($inner-container-padding, xl), 100vw) * $inner-container-max-width;

// Calculated value of inner width
$inner-container-max-inner-width: $inner-container-max-width - $inner-container-max-padding * 2;
