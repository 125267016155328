:root {
  @include use-responsive-sizing(--list-teaser-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--list-teaser-padding-block --list-teaser-padding-inline, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--list-teaser-event-padding-block --list-teaser-event-padding-inline, responsive-map(xs 1.5rem, xl 2rem));

  --list-teaser-aside-gap: 2rem;
  --list-teaser-aside-max-width: 40rem;
  --list-teaser-background-color: var(--color-gray-50);
  --list-teaser-color: var(--text-color);
  --list-teaser-date-color: var(--meta-text-color);
  --list-teaser-event-background-color: transparent;
  --list-teaser-event-background-image: var(--secondary-gradient);
  --list-teaser-event-date-color: var(--color-white);
  --list-teaser-event-date-font-size: 2.4rem;
  --list-teaser-event-place-color: var(--color-white);
  --list-teaser-event-place-font-size: var(--list-teaser-font-size);
  --list-teaser-image-offset: 3rem;
  --list-teaser-z-index: #{z-index('default')};
}

.list-teaser {
  color: var(--list-teaser-color);
  container-name: list-teaser;
  container-type: inline-size;
  position: relative;
  z-index: var(--list-teaser-z-index);
}

.list-teaser--aside {
  padding-block-start: var(--list-teaser-image-offset);
}

.list-teaser__inner {
  align-items: flex-start;
  background-color: var(--list-teaser-background-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-block: var(--list-teaser-padding-block);
  padding-inline: var(--list-teaser-padding-inline);
  row-gap: 2rem;

  @container list-teaser (min-width: 60rem) {
    column-gap: 3rem;
    flex-direction: row;
  }
}

.list-teaser__aside {
  flex-shrink: 0;
  margin-block-end: 1rem;
  margin-block-start: calc((var(--list-teaser-image-offset) + var(--list-teaser-padding-block)) * -1);
  max-width: var(--list-teaser-aside-max-width);
  width: 100%;

  @container list-teaser (min-width: 60rem) {
    margin-block-end: 0;
    width: 40%;
  }
}

.list-teaser__event {
  background-color: var(--list-teaser-event-background-color);
  background-image: var(--list-teaser-event-background-image);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-default);
  min-height: 14rem;
  padding-block: var(--list-teaser-event-padding-block);
  padding-inline: var(--list-teaser-event-padding-inline);
}

.list-teaser__event-date {
  color: var(--list-teaser-event-date-color);
  display: block;
  font-size: var(--list-teaser-event-date-font-size);
}

.list-teaser__event-place {
  color: var(--list-teaser-event-place-color);
  display: block;
  font-size: var(--list-teaser-event-place-font-size);
  margin-block-start: 0.5rem;
}

.list-teaser__content {
  display: flex;
  flex-direction: column;
  font-size: var(--list-teaser-font-size);
  gap: 0.4em;
  line-height: var(--line-height-body);
}

.list-teaser__tag {
  &:not(:last-child) {
    margin-block-end: 1rem;
  }
}

.list-teaser__headline {
  --focus-outline-width: 0;

  display: block;
  hyphens: auto;

  &:not(:last-child) {
    margin-block-end: 0.5rem;
  }

  &:focus-visible {
    &::before {
      @include use-focus-outline();

      --focus-outline-width: 3px;

      content: '';
      inset: 0;
      pointer-events: none;
      position: absolute;
    }
  }
}

.list-teaser__button {
  --focus-outline-width: 0;

  justify-self: flex-end;
  margin-block-start: auto;

  &:not(:first-child) {
    padding-block-start: 1rem;
  }
}
