:root {
  --headline-color: var(--heading-color);
  --headline-font-size: 1.8rem;
  --headline-font-weight: var(--font-weight-bold);
  --headline-kicker-color: var(--meta-text-color);
  --headline-kicker-font-size: 1.6rem;
  --headline-kicker-font-weight: var(--font-weight-regular);
  --headline-line-height: var(--line-height-small);
}

.headline {
  color: var(--headline-color);
  font-optical-sizing: none;
  font-size: var(--headline-font-size);
  font-weight: var(--headline-font-weight);
  line-height: var(--headline-line-height);
  transition-property: color;
}

.headline--1 {
  @include use-responsive-sizing(--headline-font-size, responsive-map(xs 3.2rem, xl 6rem));
}

.headline--2 {
  @include use-responsive-sizing(--headline-font-size, responsive-map(xs 2.6rem, xl 3.8rem));
}

.headline--3 {
  @include use-responsive-sizing(--headline-font-size, responsive-map(xs 2.4rem, xl 3.2rem));
}

.headline--4 {
  @include use-responsive-sizing(--headline-font-size, responsive-map(xs 2.2rem, xl 2.8rem));
}

.headline__kicker {
  --headline-kicker-spacing: calc(var(--headline-font-size) * 0.33);

  color: var(--headline-kicker-color);
  display: block;
  font-size: var(--headline-kicker-font-size);
  font-weight: var(--headline-kicker-font-weight);
  line-height: var(--line-height-default);

  &:first-child {
    margin-block-end: var(--headline-kicker-spacing);
  }

  &:last-child {
    margin-block-start: var(--headline-kicker-spacing);
  }
}
