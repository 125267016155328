:root {
  --media-caption-color: var(--meta-text-color);
  --media-caption-size: 1.4rem;
  --media-caption-padding-inline: 0;
  --media-caption-gap: 1.5rem;
}

.media {
  --link-color: currentColor;
  --link-icon-color: currentColor;
  --link-underline-color: currentColor;
  --link-underline-color-engaged: transparent;
  --link-underline-height: 1px;
  --link-underline-offset: 0.2em;

  display: block;
  transition-property: opacity;
}

.media__figure {
  --image-z-index: 2;

  overflow: hidden;
  position: relative;
  z-index: 1;
}

.media__button {
  --icon-button-padding-block: 1.5rem;
  --icon-button-padding-inline: 1.5rem;
  --icon-button-border-radius: var(--border-radius-x-large);
  --overlay-link-inset: -100vw;

  background-color: var(--color-white);
  inset-block-start: 2rem;
  inset-inline-start: 2rem;
  padding-block: 1.5rem;
  padding-inline: 2rem;
  position: absolute;
  z-index: 3;
}

.media__caption {
  position: relative;
  text-align: left;
  z-index: 2;
}

.media__copyright {
  inset-block-start: 0;
  inset-inline-end: 1rem;
  line-height: 0;
  position: absolute;
  transform: translateY(calc(-100% - 1rem));
}

.media__text {
  color: var(--media-caption-color);
  font-size: var(--media-caption-size);
  line-height: var(--line-height-body);
  padding-block-start: var(--media-caption-gap);
  padding-inline: var(--media-caption-padding-inline);
}

.media__gallery {
  --copyright-size: 3rem;
  --media-caption-color: var(--color-white);
  --icon-button-icon-size: 2.2rem;
  --icon-button-size: 2.2rem;
  --icon-button-gap: 0.5rem;
  --media-gallery-padding: 2rem;
  --image-z-index: 1;
  --navigation-button-size: 4rem;
  --text-color: var(--color-white);
  --focus-outline-color: var(--color-white);
  --selection-background-color: var(--color-white);
  --selection-foreground-color: var(--color-black);
  --link-color-engaged: currentColor;
  --link-icon-color-engaged: currentColor;
  --link-underline-color-engaged: transparent;

  animation-duration: var(--duration-fast);
  animation-name: opacity;
  backdrop-filter: blur(10px);
  background-color: rgba(#000, 0.8);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  padding-block-end: calc(var(--media-gallery-padding) * 2 + var(--navigation-button-size));
  padding-block-start: calc(var(--media-gallery-padding) * 2 + var(--icon-button-icon-size));
  padding-inline: var(--media-gallery-padding);
  position: fixed;
  z-index: z-index('overlay');

  &:is([aria-hidden='true']) {
    display: none;
  }
}

.media__gallery-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.media__gallery-images {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 1;
}

.media__gallery-image {
  animation-duration: var(--duration-x-long);
  animation-name: opacity;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-block: auto;
  position: relative;
  width: 100%;

  &:is([data-move-direction='left']) {
    animation-name: opacity, fade-in-from-left;
  }

  &:is([data-move-direction='right']) {
    animation-name: opacity, fade-in-from-right;
  }
}

.media__gallery-copyright {
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  line-height: 0;
  position: absolute;
  z-index: 3;
}

.media__gallery-caption {
  backdrop-filter: blur(10px);
  background-color: rgba(#000, 0.6);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  inset-block-end: 1rem;
  inset-inline-start: 1rem;
  line-height: var(--line-height-default);
  max-width: calc(100% - 3rem - var(--copyright-size));
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 2;
}

.media__gallery-image--hidden {
  display: none;
}

.media__gallery-controls {
  --icon-button-icon-size: 2rem;
  --icon-button-size: 4rem;

  inset: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  z-index: 2;
}

.media__gallery-close {
  --icon-button-icon-color: var(--text-color);
  --icon-button-icon-color-engaged: var(--text-color);
  --icon-button-text-color: var(--text-color);
  --icon-button-text-color-engaged: var(--text-color);
  --icon-button-background-color: transparent;
  --icon-button-background-color-hover: transparent;

  inset-block-start: var(--media-gallery-padding);
  inset-inline-end: var(--media-gallery-padding);
  line-height: 0;
  pointer-events: all;
  position: absolute;
}

.media__gallery-position {
  font-size: 1.6rem;
  font-weight: var(--font-weight-bold);
  inset-block-start: var(--media-gallery-padding);
  inset-inline-start: var(--media-gallery-padding);
  line-height: var(--icon-button-icon-size);
  position: absolute;
}

.media__gallery-buttons {
  display: flex;
  gap: 1rem;
  inset-block-end: var(--media-gallery-padding);
  inset-inline-start: 50%;
  pointer-events: all;
  position: absolute;
  transform: translateX(-50%);
}
