:root {
  @include use-responsive-sizing(--grid-row-gap, responsive-map(xs 2rem, xl 4rem));

  --grid-column-gap: var(--gap);
}

.grid {
  container-name: grid;
  container-type: inline-size;
}

.grid__items {
  display: grid;
  gap: var(--grid-column-gap);
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  row-gap: var(--grid-row-gap);

  @container grid (min-width: 40rem) {
    .grid--4 & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @container grid (min-width: 50rem) {
    .grid--3 & {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @container grid (min-width: 60rem) {
    .grid--2 & {
      grid-template-columns: repeat(2, 1fr);
    }

    .grid--4 & {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @container grid (min-width: 90rem) {
    .grid--3 & {
      grid-template-columns: repeat(3, 1fr);
    }

    .grid--4 & {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.grid__item {
  display: grid;
}
