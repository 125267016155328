:root {
  --podcast-episode-background-color: var(--color-gray-100);
  --podcast-episode-cover-size: 6rem;
  --podcast-episode-padding-block: 2rem;
  --podcast-episode-padding-inline: 2rem;
  --podcast-episode-play-background-color: var(--interactive-color);
  --podcast-episode-play-color: var(--color-white);
  --podcast-episode-title-color: var(--interactive-color);
  --podcast-episode-title-font-size: 2rem;
  --podcast-episode-warning-color: var(--meta-text-color);
  --podcast-episode-warning-font-size: 1.2rem;
  --podcast-episode-warning-line-color: var(--line-color);
}

.podcast-episode__preview {
  --podcast-episode-play-hover-opacity: 0;

  align-items: center;
  background-color: var(--podcast-episode-background-color);
  column-gap: var(--podcast-episode-padding-inline);
  display: flex;
  flex-wrap: wrap;
  padding-block: var(--podcast-episode-padding-block);
  padding-inline: var(--podcast-episode-padding-inline);
  row-gap: var(--podcast-episode-padding-block);
  width: 100%;

  &:is(:hover, :focus-visible) {
    --podcast-episode-play-hover-opacity: 1;
  }
}

.podcast-episode__preview-icon {
  background-color: var(--podcast-episode-play-background-color);
  border-radius: 50%;
  color: var(--podcast-episode-play-color);
  font-size: calc(var(--podcast-episode-cover-size) * 0.5);
  height: var(--podcast-episode-cover-size);
  line-height: 0;
  overflow: hidden;
  padding: calc(var(--podcast-episode-cover-size) * 0.25);
  position: relative;
  width: var(--podcast-episode-cover-size);
  z-index: 2;

  &::before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    inset: 0;
    opacity: var(--podcast-episode-play-hover-opacity);
    position: absolute;
    transition-property: opacity;
    z-index: -1;
  }
}

.podcast-episode__preview-episode {
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: var(--podcast-episode-padding-inline);
}

.podcast-episode__preview-episode-image {
  flex-shrink: 0;
  height: var(--podcast-episode-cover-size);
  margin-inline-start: auto;
  object-fit: cover;
  overflow: hidden;
  width: var(--podcast-episode-cover-size);
}

.podcast-episode__preview-episode-title {
  color: var(--podcast-episode-title-color);
  font-size: var(--podcast-episode-title-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-default);
  text-align: left;
}

.podcast-episode__preview-warning {
  border-block-start: 1px solid var(--podcast-episode-warning-line-color);
  color: var(--podcast-episode-warning-color);
  flex-basis: 100%;
  font-size: var(--podcast-episode-warning-font-size);
  line-height: var(--line-height-small);
  padding-block-start: var(--podcast-episode-padding-block);
  text-align: center;
}
