:root {
  @include use-responsive-sizing(--menu-topic-font-size, (xs: 1.8rem, l: 2.2rem, xl: 2.2rem));
  @include use-responsive-sizing(--menu-navigation-font-size, responsive-map(xs 1.8rem, xl 2rem));
  @include use-responsive-sizing(--menu-navigation-gap, responsive-map(xs 1.2rem, xl 1.6rem));
  @include use-responsive-sizing(--menu-content-padding-block, responsive-map(xs 3rem, xl 5rem));

  --menu-background-color: var(--primary-color);
  --menu-background-image: var(--secondary-gradient);
  --menu-close-height: 10rem;
  --menu-close-font-size: 2.2rem;
  --menu-color: var(--color-white);
  --menu-teaser-background-color: var(--color-white);
  --menu-teaser-headline-color: var(--primary-color);
  --menu-topic-background-color: var(--color-white);
  --menu-topic-color: var(--interactive-color);
}

.menu {
  --selection-background-color: var(--menu-color);
  --selection-foreground-color: var(--menu-background-color);
  --headline-color: var(--color-white);

  background-image: var(--menu-background-image);
}

.menu__inner {
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
}

@supports (-webkit-touch-callout: none) {
  .menu__inner {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    height: -webkit-fill-available;
  }
}

.menu__close {
  @include use-container();

  --icon-button-background-color: transparent;
  --icon-button-icon-size: calc(var(--menu-close-font-size) * 1.35);
  --icon-button-size: calc(var(--menu-close-font-size) * 1.35);
  --icon-button-text-size: var(--menu-close-font-size);
  --icon-button-text-color: var(--color-white);
  --icon-button-underline-height: 0.1em;
  --icon-button-background-color-hover: transparent;

  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: var(--menu-close-height);
  padding-block: 2rem;
}

.menu__content {
  @include use-container();

  column-gap: var(--gap);
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  padding-block: var(--menu-content-padding-block);
  row-gap: var(--menu-content-padding-block);
}

.menu__section {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 3rem;
}

.menu__section--topics {
  @include use-responsive-sizing(width, (
    xs: 100%,
    m: column-width(m, 7),
    l: column-width(l, 7),
    xl: column-width(xl, 7),
  ));
}

.menu__section--menu {
  @include use-responsive-sizing(width, (
    xs: 100%,
    m: column-width(m, 5),
    l: column-width(l, 4),
    xl: column-width(xl, 4),
  ));
}

.menu__section-headline {
  --headline-font-weight: var(--font-weight-extrabold);
}

.menu__topics {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.menu__topic {
  --link-color: var(--menu-topic-color);
  --link-icon-gap: 0.5em;
  --link-icon-puffer: 0;
  --link-underline-color-engaged: currentColor;
  --link-underline-color: transparent;
  --selection-background-color: var(--menu-topic-color);
  --selection-foreground-color: var(--menu-topic-background-color);
  --focus-outline-color: var(--menu-topic-color);

  background-color: var(--menu-topic-background-color);
  display: block;
  font-size: var(--menu-topic-font-size);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-body);
  padding-block: 0.3em;
  padding-inline: 0.8em;
  position: relative;
  white-space: nowrap;
}

.menu__teaser {
  --headline-color: var(--menu-teaser-headline-color);
  --list-teaser-aside-max-width: 30rem;
  --list-teaser-background-color: var(--menu-teaser-background-color);
  --selection-background-color: var(--menu-teaser-headline-color);
  --selection-foreground-color: var(--menu-teaser-background-color);

  margin-block-start: 2rem;
}

.menu__navigation {
  display: flex;
  flex-direction: column;
  row-gap: var(--menu-navigation-gap);
}

.menu__navigation-divider {
  background-color: rgba(#fff, 0.2);
  display: block;
  height: 1px;
  margin-block: calc(var(--menu-navigation-gap) * 2);
}

.menu__navigation-item {
  --icon-button-background-color: transparent;
  --icon-button-icon-size: calc(var(--menu-navigation-font-size) * 1.2);
  --icon-button-size: calc(var(--menu-navigation-font-size) * 1.2);
  --icon-button-text-size: var(--menu-navigation-font-size);
  --icon-button-text-color: var(--color-white);
  --icon-button-background-color-hover: transparent;
  --icon-button-font-weight: var(--font-weight-semibold);

  display: block;
}
