:root {
  @include use-responsive-sizing(--quote-font-size, responsive-map(xs 1.6rem, xl 2.4rem));
  @include use-responsive-sizing(--quote-large-font-size, responsive-map(xs 1.8rem, xl 2.8rem));
  @include use-responsive-sizing(--quote-image-size, responsive-map(xs 6rem, xl 10rem));
  @include use-responsive-sizing(--quote-large-image-size, responsive-map(xs 8rem, xl 12rem));
  @include use-responsive-sizing(--quote-image-margin-block, responsive-map(xs 2rem, xl 2.5rem));
  @include use-responsive-sizing(--quote-padding-block, responsive-map(xs 3rem, xl 5rem));

  --quote-caption-color: var(--text-color);
  --quote-caption-font-size: 1.6rem;
  --quote-caption-line-height: var(--line-height-default);
  --quote-color: var(--text-color);
  --quote-font-weight: var(--font-weight-medium);
  --quote-gap: 1.5rem;
  --quote-large-color: var(--primary-color);
  --quote-large-font-weight: var(--font-weight-bold);
  --quote-line-color: var(--color-red-100);
  --quote-line-height: var(--line-height-body);
  --quote-line-width: 0.2rem;
  --quote-padding-block: 0;
  --quote-padding-inline: 0;
  --quote-z-index: #{z-index('default')};
}

.quote {
  padding-block: var(--quote-padding-block);
  padding-inline: var(--quote-padding-inline);
  position: relative;
  text-align: center;
  z-index: var(--quote-z-index);

  &::before,
  &::after {
    background-color: var(--quote-line-color);
    content: '';
    height: var(--quote-line-width);
    inset-inline-start: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 25%;
  }

  &::before {
    inset-block-start: 0;
  }

  &::after {
    inset-block-end: 0;
  }
}

.quote--large {
  --quote-font-size: var(--quote-large-font-size);
  --quote-color: var(--quote-large-color);
  --quote-font-weight: var(--quote-large-font-weight);
}

.quote__content {
  line-height: var(--line-height-default);
  position: relative;
  z-index: 2;
}

.quote__image {
  height: var(--quote-image-size);
  margin-block-end: var(--quote-image-margin-block);
  object-fit: cover;
  overflow: hidden;
  width: var(--quote-image-size);
}

.quote__quote {
  color: var(--quote-color);
  font-size: var(--quote-font-size);
  font-weight: var(--quote-font-weight);
  line-height: var(--quote-line-height);

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.quote__caption {
  color: var(--quote-caption-color);
  font-size: var(--quote-caption-font-size);
  line-height: var(--quote-caption-line-height);
  margin-block-start: var(--quote-gap);

  &::before {
    background-color: currentColor;
    content: '';
    display: inline-block;
    height: 1px;
    margin-inline-end: 1rem;
    vertical-align: middle;
    width: 1rem;
  }
}
