:root {
  --logo-wall-gap: var(--gap);
  --logo-wall-highlight-color: var(--primary-color);
  --logo-wall-logo-background: var(--color-white);
  --logo-wall-logo-padding-block: 2rem;
  --logo-wall-logo-padding-inline: 2rem;
  --logo-wall-padding-block: 0;
  --logo-wall-padding-inline: 0;
  --logo-wall-text-gap: 1rem;
  --logo-wall-text-size: 1.6rem;
}

.logo-wall {
  display: grid;
  gap: var(--logo-wall-gap);
  grid-auto-rows: auto;
  grid-template-columns: repeat(var(--logo-wall-columns, 1), minmax(0, 1fr));
  padding-block: var(--logo-wall-padding-block);
  padding-inline: var(--logo-wall-padding-inline);
}

.logo-wall--3 {
  @include use-responsive-sizing(--logo-wall-columns, (
    xs: 2,
    m: 3,
  ));
}

.logo-wall--4 {
  @include use-responsive-sizing(--logo-wall-columns, (
    xs: 2,
    m: 4,
  ));
}

.logo-wall--6 {
  @include use-responsive-sizing(--logo-wall-columns, (
    xs: 2,
    s: 3,
    m: 4,
    l: 6,
  ));
}

.logo-wall__logo {
  align-items: center;
  background-color: var(--logo-wall-logo-background);
  display: flex;
  flex-direction: column;
  gap: var(--logo-wall-text-gap);
  justify-content: center;
  padding-block: var(--logo-wall-logo-padding-block);
  padding-inline: var(--logo-wall-logo-padding-inline);
  transition-property: transform;

  &:any-link:is(:hover, :focus-visible) {
    transform: scale(1.05);
  }
}

.logo-wall__logo--highlight {
  box-shadow: inset 0 0 0 3px var(--logo-wall-highlight-color);
}

.logo-wall__logo-text {
  font-size: var(--logo-wall-text-size);
  line-height: var(--line-height-small);
  text-align: center;
}

.logo-wall__logo-image {
  height: auto;
  max-height: 10rem;
  max-width: 20rem;
  object-fit: contain;
  width: 100%;
}
