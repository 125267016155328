// stylelint-disable order/order

@use 'sass:map';

:root {
  // Base colors
  --color-white: #{$color-white};
  --color-black: #{$color-black};

  // Red shares
  --color-red-100: #{$color-red-100};
  --color-red-300: #{$color-red-300};
  --color-red-400: #{$color-red-400};
  --color-red-500: #{$color-red-500};
  --color-red-600: #{$color-red-600};

  // Purple shades
  --color-purple-600: #{$color-purple-600};
  --color-purple-700: #{$color-purple-700};

  // Blue shades
  --color-blue-300: #{$color-blue-300};
  --color-blue-500: #{$color-blue-500};

  // Magenta
  --color-magenta-500: #{$color-magenta-500};
  --color-magenta-700: #{$color-magenta-700};

  // Orange
  --color-orange-600: #{$color-orange-600};

  // Green
  --color-green-600: #{$color-green-600};

  // Greys
  --color-gray-50: #{$color-gray-50};
  --color-gray-100: #{$color-gray-100};
  --color-gray-200: #{$color-gray-200};
  --color-gray-300: #{$color-gray-300};
  --color-gray-400: #{$color-gray-400};
  --color-gray-500: #{$color-gray-500};
  --color-gray-600: #{$color-gray-600};
  --color-gray-700: #{$color-gray-700};
  --color-gray-800: #{$color-gray-800};
  --color-gray-900: #{$color-gray-900};

  // Aliases
  --primary-color: var(--color-red-500);
  --secondary-color: var(--color-purple-600);
  --tertiary-color: var(--color-magenta-500);
  --text-color: var(--color-black);
  --heading-color: var(--primary-color);
  --interactive-color: var(--secondary-color);
  --interactive-hover-color: var(--color-purple-700);
  --meta-text-color: var(--color-gray-800);
  --light-background-color: var(--color-gray-50);
  --background-color: var(--color-gray-100);
  --info-color: var(--color-blue-500);
  --success-color: var(--color-green-600);
  --warning-color: var(--color-orange-600);
  --error-color: var(--color-red-600);
  --form-color: var(--secondary-color);
  --form-placeholder-color: var(--color-gray-400);
  --form-border-radius: 0;
  --required-color: var(--secondary-color);
  --line-color: var(--color-gray-300);
  --light-line-color: var(--color-gray-100);
  --shadow-color: var(--color-gray-300);
  --light-shadow-color: var(--color-gray-200);

  // Gradients
  --primary-gradient: linear-gradient(to left, var(--color-red-500) 30%, var(--color-magenta-500) 60%, var(--color-magenta-700) 100%);
  --secondary-gradient: linear-gradient(to right, var(--color-red-500) 20%, var(--color-magenta-500) 80%);

  // Font families
  --font-family-skolar-sans-pe: 'Skolar Sans PE', sans-serif;

  // Font weights
  --font-weight-light: 250;
  --font-weight-regular: 300;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 750;

  // Font widths
  --font-stretch-compressed: 80%;
  --font-stretch-condensed: 90%;
  --font-stretch-normal: 100%;
  --font-stretch-extended: 115%;

  // Line heights
  --line-height-small: 1.2;
  --line-height-default: 1.4;
  --line-height-body: 1.6;

  // Durations
  --duration-fast: 0.1s;
  --duration-default: 0.2s;
  --duration-long: 0.3s;
  --duration-x-long: 0.4s;

  // Border radius
  --border-radius-default: 0.5rem;

  // Root
  --root-offset: 0;
  --root-font-family: var(--font-family-skolar-sans-pe);
  --root-color: var(--text-color);
  --root-min-width: #{$root-min-width};
  --root-font-width: var(--font-width-normal);
  --root-font-weight: var(--font-weight-regular);
  --root-font-stretch: var(--font-stretch-normal);

  // Body
  --body-background-color: var(--color-white);
  --body-font-size: 1.8rem;

  // Focus outlines
  --focus-outline-width: 3px;
  --focus-outline-color: var(--color-black);
  --focus-outline-offset: 3px;

  // Selection
  --selection-background-color: var(--color-black);
  --selection-foreground-color: var(--color-white);

  // Highlight
  --highlight-background-color: var(--secondary-color);
  --highlight-foreground-color: var(--color-white);

  // Timing functions
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

  // Breakpoints
  --breakpoint-l: #{map.get($mq-breakpoints, l)};
  --breakpoint-m: #{map.get($mq-breakpoints, m)};
  --breakpoint-s: #{map.get($mq-breakpoints, s)};
  --breakpoint-xl: #{map.get($mq-breakpoints, xl)};
  --breakpoint-xs: #{map.get($mq-breakpoints, xs)};

  // Text sizes
  @include use-responsive-sizing(--font-size-default, responsive-map(xs 1.6rem, xl 2rem));

  // Grid gaps
  @include use-responsive-sizing(--gap, $gaps);

  // Container and body paddings
  @include use-body-padding($properties: --inner-container-padding --body-padding);
  @include mq($from: rem2px($inner-container-max-width)) {
    --body-padding: calc((100vw - #{$inner-container-max-width}) / 2 + var(--inner-container-padding));
  }
}
