:root {
  @include use-responsive-sizing(--header-logo-width, responsive-map(xs 6rem, xl 8rem));
  @include use-responsive-sizing(--header-padding-block, responsive-map(xs 2rem, xl 2rem));
  @include use-responsive-sizing(--header-icon-button-size, responsive-map(xs 1.6rem, xl 2.2rem));

  --header-background-image: var(--primary-gradient);
  --header-height: calc(var(--header-padding-block) + var(--header-logo-width) * 1.334);
  --header-z-index: #{z-index('header')};
}

.header {
  --focus-outline-color: var(--color-white);

  background-image: var(--header-background-image);
  position: relative;
  z-index: var(--header-z-index);
}

.header__inner {
  @include use-container();

  align-items: center;
  display: flex;
  gap: calc(var(--header-icon-button-size) * 1.2);
  height: var(--header-height);
  padding-block: var(--header-padding-block);
  position: relative;
}

.header__logo-container {
  inset-block-start: 0;
  inset-inline-end: var(--inner-container-padding);
  line-height: 0;
  position: absolute;
  width: var(--header-logo-width);
  z-index: 4;
}

.header__logo {
  display: block;
  transform-origin: 100% 0;
  transition-property: scale;
  will-change: scale;

  .header--large-logo & {
    @include mq($from: m) {
      scale: 1.5;
    }
  }
}

.header__button {
  --icon-button-background-color: transparent;
  --icon-button-icon-size: calc(var(--header-icon-button-size) * 1.35);
  --icon-button-size: calc(var(--header-icon-button-size) * 1.35);
  --icon-button-text-size: var(--header-icon-button-size);
  --icon-button-text-color: var(--color-white);
  --icon-button-background-color-hover: transparent;
  --icon-button-underline-height: 0.1em;
}

.header__menu-overlay {
  --menu-close-height: var(--header-height);
  --menu-close-font-size: var(--header-icon-button-size);

  animation: fade-in-from-top var(--duration-long) var(--ease-out-expo), opacity var(--duration-default) var(--ease-out-expo);
  inset: 0;
  position: fixed;
  z-index: 3;
}

.header__search-overlay {
  inset-block-start: var(--header-height);
  inset-inline: 0;
  position: fixed;
  z-index: 1;
}

.header__search-form {
  --focus-outline-color: var(--form-color);

  animation: fade-in-from-top var(--duration-long) var(--ease-out-expo), opacity var(--duration-default) var(--ease-out-expo);
  background-color: var(--color-gray-50);
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding-block: 3rem;
  padding-inline: var(--inner-container-padding);
  position: relative;
  z-index: 2;
}

.header__search-input {
  flex-grow: 1;
  max-width: 40rem;
}

.header__search-button {
  flex-shrink: 0;
}

.header__search-close {
  --icon-button-size: 5.2rem;
  --icon-button-icon-size: 2.4rem;

  flex-shrink: 0;
}

.header__search-backdrop {
  animation: opacity var(--duration-long) var(--ease-out-expo);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.4);
  content: '';
  inset-block-end: 0;
  inset-block-start: var(--header-height);
  inset-inline: 0;
  position: fixed;
  z-index: 1;
}
