:root {
  @include use-responsive-sizing(--page-actions-gap, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(--page-block-spacing-factor, responsive-map(xs 1.5, xl 2));
  @include use-responsive-sizing(--page-block-spacing, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--page-breadcrumb-padding-block, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--page-footnotes-padding-block, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(--page-footnotes-size, responsive-map(xs 1.2rem, xl 1.4rem));
  @include use-responsive-sizing(--page-last-modified-size, responsive-map(xs 1.4rem, xl 1.6rem));
  @include use-responsive-sizing(--page-paragraph-size, responsive-map(xs 1.6rem, xl 2.3rem));
  @include use-responsive-sizing(--page-section-padding-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-sizing(--page-toolbar-gap, responsive-map(xs 2rem, xl 4rem));

  --page-block-spacing-extended: calc(var(--page-block-spacing) * var(--page-block-spacing-factor));
  --page-block-spacing-full: calc(var(--page-block-spacing) * var(--page-block-spacing-factor) * 1.5);
  --page-footnotes-color: var(--meta-text-color);
  --page-footnotes-gap: 0.5rem;
  --page-footnotes-return-background: var(--background-color);
  --page-footnotes-return-foreground: var(--text-color);
  --page-last-modified-color: var(--meta-text-color);
  --page-line-height: var(--line-height-body);
  --page-section-border-color: var(--color-gray-50);
  --page-section-border-width: 0.5rem;
}

.page__content {
  @include use-container();
}

.page__breadcrumb {
  padding-block: var(--page-breadcrumb-padding-block);
}

.page__head {
  @include use-responsive-sizing(width, (
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 10),
  ));

  border-block-end: var(--page-section-border-width) solid var(--page-section-border-color);
  margin-inline: auto;
  padding-block: var(--page-section-padding-block);

  .page__breadcrumb + & {
    padding-block-start: calc(var(--page-section-padding-block) - var(--page-breadcrumb-padding-block));
  }

  @include mq($until: s) {
    .page__breadcrumb + & {
      padding-block-start: 0;
    }
  }
}

.page__blocks {
  @include use-responsive-sizing(width, (
    m: column-width(m, 8),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  clear: both;
  margin-inline: auto;
  padding-block: var(--page-section-padding-block);

  .page__breadcrumb + & {
    padding-block-start: calc(var(--page-section-padding-block) - var(--page-breadcrumb-padding-block));
  }

  &:last-child {
    padding-block-end: calc(var(--page-section-padding-block) * 2);
  }

  @include mq($until: s) {
    .page__breadcrumb + & {
      padding-block-start: 0;
    }
  }
}

.page__block {
  font-size: var(--page-paragraph-size);
  line-height: var(--page-line-height);
  margin-block: var(--page-block-spacing);

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  &:first-child > *:first-child {
    margin-block-start: 0;
  }

  > .headline {
    margin-block-end: var(--page-block-spacing);
    margin-block-start: calc(var(--page-block-spacing) * 2);
  }

  > .headline + .headline {
    margin-block-start: 0;
  }

  > .highlight-text {
    --highlight-horizontal-rule-margin-block: var(--page-section-padding-block);
  }

  > .horizontal-rule {
    @include use-responsive-sizing(--horizontal-rule-margin-inline, (
      m: column-offset(m, -1, $number-of-columns: 8),
      l: column-offset(l, -1, $number-of-columns: 8),
      xl: column-offset(xl, -1, $number-of-columns: 8),
    ));

    --horizontal-rule-width: auto;
    --horizontal-rule-margin-block: var(--page-section-padding-block);
  }

  > * + *:not(.horizontal-rule) {
    margin-block-start: var(--page-block-spacing);
  }
}

.page__block--extended {
  margin-block: var(--page-block-spacing-extended);

  &[data-block-type='accordion'] + &[data-block-type='accordion'] {
    margin-block-start: calc(var(--page-block-spacing-extended) / -2);
  }
}

.page__block--left,
.page__block--right, {
  @include use-responsive-sizing(--page-block-left-right-offset, (
    m: column-offset(m, -2, $number-of-columns: 8),
    l: column-offset(l, -2, $number-of-columns: 8),
    xl: column-offset(xl, -2, $number-of-columns: 8),
  ));

  @include use-responsive-sizing(width, (
    m: column-width(m, 5, $number-of-columns: 8),
    l: column-width(l, 5, $number-of-columns: 8),
    xl: column-width(xl, 5, $number-of-columns: 8),
  ));

  @include mq($until: m) {
    margin-block: var(--page-block-spacing-extended);
  }

  @include mq($from: m) {
    clear: both;

    &:not(:first-child) {
      margin-block-start: calc(var(--page-line-height) * 0.3em);
    }

    + .page__block {
      margin-block-start: 0;
    }
  }
}

.page__block--left {
  @include mq($from: m) {
    float: left;
    margin-inline-end: var(--gap);
    margin-inline-start: var(--page-block-left-right-offset);
  }
}

.page__block--right {
  @include mq($from: m) {
    float: right;
    margin-inline-end: var(--page-block-left-right-offset);
    margin-inline-start: var(--gap);
  }
}

.page__block--popout {
  @include use-responsive-sizing(margin-inline, (
    m: column-offset(m, -1, $number-of-columns: 8),
    l: column-offset(l, -1, $number-of-columns: 8),
    xl: column-offset(xl, -1, $number-of-columns: 8),
  ));

  margin-block: var(--page-block-spacing-extended);
}

.page__block--feature {
  @include use-responsive-sizing(margin-inline, (
    m: column-offset(m, -2, $number-of-columns: 8),
    l: column-offset(l, -2, $number-of-columns: 8),
    xl: column-offset(xl, -2, $number-of-columns: 8),
  ));

  margin-block: var(--page-block-spacing-extended);
}

.page__block--full {
  @include use-break-out();

  --section-padding-block: var(--page-section-padding-block);

  border-block: var(--page-section-border-width) solid var(--page-section-border-color);
  margin-block: var(--page-block-spacing-full);

  & + &,
  & + .page__block--hidden + & {
    border-block-start: 0;
    margin-block-start: calc(var(--page-block-spacing-full) * -1);
  }

  &:first-child {
    border-block-start: 0;
    margin-block-start: calc(var(--page-section-padding-block) * -1);
  }

  .page__breadcrumb + .page__blocks &:first-child {
    margin-block-start: calc((var(--page-section-padding-block) - var(--page-breadcrumb-padding-block)) * -1);
  }

  &:last-child {
    margin-block-end: calc(var(--page-section-padding-block) * -1);
  }
}

.page__block--hidden {
  .page__block--full + & {
    margin-block-start: calc(var(--page-block-spacing-full) * -1);
  }
}

.page__appendix {
  @include use-responsive-sizing(width, (
    m: column-width(m, 8),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  color: var(--page-footnotes-color);
  font-size: var(--page-footnotes-size);
  line-height: var(--page-line-height);
  margin-inline: auto;
  padding-block: var(--page-section-padding-block);
  position: relative;

  &::before {
    background-color: var(--line-color);
    content: '';
    display: block;
    height: 1px;
    width: 15rem;
  }
}

.page__small-print {
  padding-block-start: var(--page-footnotes-padding-block);
}

.page__footnotes {
  padding-block-start: var(--page-footnotes-padding-block);
  padding-inline-start: 1.5rem;
}

.page__footnote {
  padding-inline-start: 0.8rem;

  & + & {
    margin-block-start: var(--page-footnotes-gap);
  }
}

.page__footnote-return {
  background-color: var(--page-footnotes-return-background);
  border-radius: 0.25em;
  color: var(--page-footnotes-return-foreground);
  display: inline-block;
  font-size: 0.8em;
  height: 1.25em;
  line-height: 1.4em;
  text-align: center;
  transition-property: background-color, color;
  width: 1.25em;

  &:is(:hover, :focus-visible) {
    background-color: var(--page-footnotes-return-foreground);
    color: var(--page-footnotes-return-background);
  }
}

.page__toolbar {
  @include use-break-out();

  border-block-start: var(--page-section-border-width) solid var(--page-section-border-color);
  margin-block-start: calc(var(--page-section-border-width) * -1);
}

.page__toolbar-inner {
  @include use-container();

  display: flex;
  flex-direction: column;
  gap: var(--page-toolbar-gap);
  margin-inline: auto;
  padding-block-end: var(--page-section-padding-block);
  padding-block-start: max(2.5rem, calc(var(--page-section-padding-block) / 2));

  @include mq($from: s) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.page__last-modified {
  color: var(--page-last-modified-color);
  font-size: var(--page-last-modified-size);
  line-height: var(--page-line-height);
}

.page__actions {
  display: flex;
  gap: var(--page-actions-gap);

  @include mq($from: s) {
    &:first-child {
      margin-inline-start: auto;
    }
  }
}

@include mq($until: s) {
  .page__block--left,
  .page__block--right,
  .page__block--popout,
  .page__block--feature,
  .page__block--extended,
  .page__block--full {
    --highlight-text-padding-inline: var(--inner-container-padding);
    --highlight-text-with-background-padding-inline: var(--inner-container-padding);
    --media-caption-padding-inline: var(--inner-container-padding);
    --highlight-padding-inline: var(--inner-container-padding);
    --quote-padding-inline: var(--inner-container-padding);
    --dropdown-padding-inline: var(--inner-container-padding);
    --buttons-padding-inline: var(--inner-container-padding);
    --logo-wall-padding-inline: var(--inner-container-padding);
    --accordion-padding-inline: var(--inner-container-padding);
    --embed-wall-padding-inline: var(--inner-container-padding);
    --embed-content-padding-inline: var(--inner-container-padding);
    --event-details-box-padding-inline: var(--inner-container-padding);
    --people-padding-inline: var(--inner-container-padding);
    --details-item-padding-inline: var(--inner-container-padding);
    --list-teasers-pagination-padding-inline: var(--inner-container-padding);
    --accordion-border-width-inline: 0;
    --details-border-width: 1px 0;

    margin-inline: calc(var(--inner-container-padding) * -1);
  }
}

@include mq($from: s, $until: m) {
  .page__block:not(.page__block--full) {
    margin-inline: auto;
    max-width: 50rem;
  }
}
