:root {
  @include use-responsive-sizing(--event-details-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--event-details-box-padding-block --event-details-box-padding-inline, responsive-map(xs 2rem, xl 3rem));

  --event-details-box-background-color: var(--color-red-100);
  --event-details-box-date-color: var(--primary-color);
}

.event-details-box {
  align-items: center;
  background-color: var(--event-details-box-background-color);
  container-name: event-details-box;
  container-type: inline-size;
  font-size: var(--event-details-box-font-size);
  line-height: var(--line-height-body);
  padding-block: var(--event-details-box-padding-block);
  padding-inline: var(--event-details-box-padding-inline);
}

.event-details-box__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @container event-details-box (min-width: 60rem) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.event-details-box__details {
  min-width: 35rem;
}

.event-details-box__date {
  color: var(--event-details-box-date-color);
  display: inline-block;
  font-size: 1.3em;
  margin-block-end: 0.2em;
}
