:root {
  --label-color: var(--meta-text-color);
  --label-font-size: 1.4rem;
  --label-line-height: var(--line-height-body);
  --label-required-color: var(--required-color);
  --label-font-weight: var(--font-weight-semibold);
}

.label {
  color: var(--label-color);
  display: block;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  line-height: var(--label-line-height);
}

.label__required {
  color: var(--label-required-color);
  cursor: help;
  display: inline-block;
  font-size: 2em;
  font-weight: var(--font-weight-extrabold);
  height: 0.65em;
  line-height: 1;
  margin-inline-start: 0.5rem;
  overflow: hidden;
  vertical-align: middle;
}
