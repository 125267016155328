:root {
  @include use-responsive-sizing(--dropdown-button-font-size, responsive-map(xs 1.8rem, xl 2.2rem));
  @include use-responsive-sizing(--dropdown-items-font-size, responsive-map(xs 1.6rem, xl 2rem));

  --dropdown-padding-block: 0;
  --dropdown-padding-inline: 0;
  --dropdown-items-border-color: var(--line-color);
  --dropdown-items-background-color: var(--color-white);
  --dropdown-button-border-color: var(--line-color);
  --dropdown-button-color: var(--interactive-color);
  --dropdown-button-icon-color: var(--interactive-color);
  --dropdown-button-background-color: var(--color-white);
  --dropdown-button-background-color-engaged: var(--color-gray-50);
  --dropdown-z-index: #{z-index('dropdown')};
}

.dropdown {
  font-size: var(--dropdown-button-font-size);
  padding-block: var(--dropdown-padding-block);
  padding-inline: var(--dropdown-padding-inline);
  position: relative;
}

.dropdown__trigger {
  display: inline-block;
}

.dropdown__button {
  align-items: center;
  background-color: var(--dropdown-button-background-color);
  border: 1px solid var(--dropdown-button-border-color);
  color: var(--dropdown-button-color);
  column-gap: 0.5em;
  display: inline-flex;
  font-weight: var(--font-weight-bold);
  padding-block: 0.6em;
  padding-inline: 1.1em;
  transition-property: background-color;
  user-select: none;

  &:hover,
  &:focus-visible {
    background-color: var(--dropdown-button-background-color-engaged);
  }
}

.dropdown__button-icon {
  color: var(--dropdown-button-icon-color);
  line-height: 0;
}

.dropdown__inner {
  position: relative;
}

.dropdown__items {
  background-color: var(--dropdown-items-background-color);
  border: 1px solid var(--dropdown-items-border-color);
  inset-block-start: calc(100% - 1px);
  inset-inline-start: 0;
  line-height: var(--line-height-body);
  max-width: 40rem;
  position: absolute;
  width: 100%;
  z-index: var(--dropdown-z-index);
}

.dropdown__item {
  --link-icon-gap: 0.8em;

  border-block-start: 1px solid var(--dropdown-items-border-color);
  display: block;
  font-size: 0.9em;
  padding-block: 0.5em;
  padding-inline: 1.1em;
  position: relative;
  user-select: none;

  &:first-child {
    border-block-start: 0;
  }
}
