:root {
  --notice-color: var(--meta-text-color);
  --notice-font-size: inherit;
  --notice-icon-color: var(--meta-text-color);
  --notice-icon-size: 1.3em;
  --notice-gap: 0.75em;
}

.notice {
  color: var(--notice-color);
  display: flex;
  font-size: var(--notice-font-size);
  gap: var(--notice-gap);
}

.notice__icon {
  color: var(--notice-icon-color);
  flex-shrink: 0;
  font-size: var(--notice-icon-size);
  line-height: 0;
}

.notice__text {
  --link-color-engaged: var(--text-color);
  --link-color: var(--notice-color);
  --link-icon-color: var(--notice-color);
  --link-icon-size: 0.8em;
  --link-underline-color: var(--notice-color);
  --link-underline-height: 1px;
  --link-underline-offset: 0.2em;

  line-height: var(--line-height-default);
  margin-block-start: calc((var(--notice-icon-size) - var(--line-height-default)) / 2);
}
