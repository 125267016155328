:root {
  --field-add-group-margin-block: 2rem;
  --field-controls-column-gap: 2rem;
  --field-controls-row-gap: 1.2rem;
  --field-errors-background-color: var(--color-red-100);
  --field-errors-color: var(--error-color);
  --field-errors-font-size: 1.4rem;
  --field-errors-font-weight: var(--font-weight-semibold);
  --field-errors-margin-block: 1.5rem;
  --field-groups-column-gap: var(--gap);
  --field-groups-margin-block: 2rem;
  --field-groups-row-gap: 3rem;
  --field-instructions-margin-block: 0.7rem;
  --field-label-margin-block: 1rem;
  --field-repeatable-group-background-color: var(--light-background-color);
  --field-repeatable-group-border-color: var(--form-light-color);
  --field-repeatable-group-border-radius: var(--form-border-radius);
  --field-repeatable-group-counter-color: var(--form-color);
  --field-repeatable-group-counter-size: 2.4rem;
  --field-repeatable-group-delete-margin-block: 2rem;
  --field-repeatable-group-delete-size: 1.6rem;
  --field-repeatable-group-padding-block: 2rem;
  --field-repeatable-group-padding-inline: 2rem;
}

.field {
  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.field--error {
  background-color: var(--field-errors-background-color);
  padding: 2rem;
}

.field__label {
  display: block;
  float: left;
  margin-block: var(--field-label-margin-block);
  padding-inline: 0;
  width: 100%;

  + * {
    clear: both;
  }
}

.field__errors {
  --notice-color: var(--field-errors-color);
  --notice-icon-color: var(--field-errors-color);

  font-size: var(--field-errors-font-size);
  font-weight: var(--field-errors-font-weight);
  margin-block: var(--field-errors-margin-block);

  .u-hidden-visually + & {
    margin-block-start: 0;
  }
}

.field__controls {
  clear: both;
  column-gap: var(--field-controls-column-gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: var(--field-controls-row-gap);
}

.field__controls--stacked {
  flex-direction: column;
  flex-wrap: nowrap;
}

.field__control {
  --horizontal-rule-margin-block: var(--field-groups-row-gap);

  flex-grow: 1;
  min-width: min-content;
}

.field__groups {
  clear: both;
  counter-reset: field-groups;
  margin-block: var(--field-groups-margin-block);
}

.field__group {
  position: relative;

  & + & {
    margin-block-start: var(--field-groups-row-gap);
  }
}

.field__groups--repeatable .field__group {
  background-color: var(--field-repeatable-group-background-color);
  border: 1px solid var(--field-repeatable-group-border-color);
  border-radius: var(--field-repeatable-group-border-radius);
  counter-increment: field-groups;
  margin-block-end: var(--field-groups-row-gap);
  padding-block: var(--field-repeatable-group-padding-block);
  padding-inline: var(--field-repeatable-group-padding-inline);

  &::before {
    border: 1px solid var(--field-repeatable-group-counter-color);
    border-radius: 50%;
    color: var(--field-repeatable-group-counter-color);
    content: counter(field-groups);
    font-size: calc(var(--field-repeatable-group-counter-size) * 0.6);
    height: var(--field-repeatable-group-counter-size);
    inset-block-start: var(--field-repeatable-group-padding-block);
    inset-inline-start: var(--field-repeatable-group-padding-inline);
    line-height: calc(var(--field-repeatable-group-counter-size) - 2px);
    position: absolute;
    text-align: center;
    width: var(--field-repeatable-group-counter-size);
  }
}

.field__group-row {
  display: grid;
  gap: var(--field-groups-column-gap);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  & + & {
    margin-block-start: var(--field-groups-row-gap);
  }
}

.field__group-delete {
  --icon-button-size: var(--field-repeatable-group-delete-size);

  margin-block-end: var(--field-repeatable-group-delete-margin-block);
  text-align: right;
}

.field__group-field {
  --label-font-weight: var(--font-weight-regular);
}

.field__instructions {
  font-size: 1.4rem;
  margin-block: var(--field-instructions-margin-block);
}
