:root {
  @include use-responsive-sizing(--contact-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--contact-box-padding-block --contact-box-padding-inline, responsive-map(xs 2rem, xl 3rem));

  --contact-box-background-color: var(--color-red-100);
  --contact-box-color: var(--text-color);
  --contact-box-column-gap: var(--gap);
  --contact-box-description-margin-block: 2rem;
  --contact-box-headline-color: var(--headline-color);
  --contact-box-headline-margin-block: 2rem;
  --contact-box-icon-gap: 1rem;
  --contact-box-image-offset: 3rem;
  --contact-box-image: 20rem;
  --contact-box-line-height: var(--line-height-body);
  --contact-box-link-gap: 0.5rem;
  --contact-box-links-margin-block: 3.5rem;
  --contact-box-row-gap: 2.5rem;
  --contact-box-z-index: #{z-index('default')};
}

.contact-box {
  container-name: contact-box;
  container-type: inline-size;
  padding-block-start: var(--contact-box-image-offset);
  position: relative;
  z-index: var(--contact-box-z-index);
}

.contact-box__inner {
  background-color: var(--contact-box-background-color);
  color: var(--contact-box-color);
  column-gap: var(--contact-box-column-gap);
  display: flex;
  flex-direction: column;
  font-size: var(--contact-box-font-size);
  height: 100%;
  line-height: var(--contact-box-line-height);
  padding-block: var(--contact-box-padding-block);
  padding-inline: var(--contact-box-padding-inline);
  row-gap: var(--contact-box-row-gap);
  text-align: left;

  @container contact-box (min-width: 60rem) {
    flex-direction: row;
  }
}

.contact-box__image {
  --image-z-index: 2;

  align-self: flex-start;
  flex-shrink: 0;
  margin-block-start: calc(var(--contact-box-image-offset) * -1 - var(--contact-box-padding-block));
  position: relative;
  width: var(--contact-box-image);
}

.contact-box__image-copyright {
  inset-block-end: 1rem;
  inset-inline-start: 1rem;
  position: absolute;
  z-index: 3;
}

.contact-box__content {
  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.contact-box__headline {
  --headline-color: var(--contact-box-headline-color);
  --headline-kicker-font-size: var(--contact-box-font-size);
  --headline-kicker-font-weight: var(--font-weight-semibold);
  --headline-kicker-line-height: var(--contact-box-line-height);
  --headline-kicker-color: var(--contact-box-color);

  margin-block: var(--contact-box-headline-margin-block);
}

.contact-box__description {
  margin-block: var(--contact-box-description-margin-block);
}

.contact-box__links {
  margin-block: var(--contact-box-links-margin-block);
}

.contact-box__link {
  display: block;

  & + & {
    margin-block-start: 0.7rem;
  }
}
