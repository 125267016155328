:root {
  --skip-link-background-color: var(--text-color);
  --skip-link-color: var(--color-white);
}

.skip-link {
  background-color: var(--skip-link-background-color);
  color: var(--skip-link-color);
  font-size: 2rem;
  font-weight: bold;
  inset-block-start: 0;
  inset-inline: 0;
  outline: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  text-align: center;
  transform: translateY(-500%);
  z-index: z-index('skip-link');

  &:is(:hover, :focus, :active) {
    transform: translateY(0);
  }

  @media print {
    display: none;
  }
}
