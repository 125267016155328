@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-from-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fade-in-from-top {
  0% {
    translate: 0 -10%;
  }

  100% {
    translate: 0;
  }
}

@keyframes fade-in-from-left {
  0% {
    translate: -1rem 0;
  }

  100% {
    translate: 0;
  }
}

@keyframes fade-in-from-right {
  0% {
    translate: 1rem 0;
  }

  100% {
    translate: 0;
  }
}
