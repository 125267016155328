:root {
  --radio-border-color-disabled: var(--text-color);
  --radio-border-color: var(--form-color);
  --radio-border-width: 2px;
  --radio-font-size: var(--font-size-default);
  --radio-gap: 1.5rem;
  --radio-line-height: 2.2rem;
  --radio-marker-color: var(--form-color);
  --radio-size: 2.8rem;
  --radio-text-color: var(--text-color);
}

.radio {
  cursor: pointer;
  display: flex;
  gap: var(--radio-gap);
  position: relative;
}

.radio--disabled {
  cursor: default;
  opacity: 0.5;
}

.radio__input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.radio__marker {
  --focus-outline-color: var(--radio-border-color);

  align-items: center;
  border: var(--radio-border-width) solid var(--radio-border-color);
  border-radius: 50%;
  color: transparent;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: var(--radio-size);
  justify-content: center;
  transition-property: border-color;
  user-select: none;
  width: var(--radio-size);

  .radio__input:focus-visible ~ & {
    @include use-focus-outline();
  }

  .radio__input[disabled] ~ & {
    border-color: var(--radio-border-color-disabled);
  }

  &::before {
    background-color: var(--radio-marker-color);
    border-radius: 50%;
    content: '';
    display: block;
    height: calc(var(--radio-size) / 2);
    opacity: 0;
    transition-property: opacity;
    width: calc(var(--radio-size) / 2);
  }

  .radio__input[disabled] ~ &::before {
    background-color: var(--radio-border-color-disabled);
  }

  .radio__input:checked ~ &::before {
    opacity: 1;
  }
}

.radio__label {
  color: var(--radio-text-color);
  font-size: var(--radio-font-size);
  line-height: var(--radio-line-height);
  margin-block-start: calc((var(--radio-size) - var(--radio-line-height)) / 2);
  text-align: left;
}
