@font-face {
  font-display: swap;
  font-family: 'Skolar Sans PE';
  font-style: normal;
  src: url('../../fonts/skolar-sans-pe-uprights.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Skolar Sans PE';
  font-style: italic;
  src: url('../../fonts/skolar-sans-pe-italics.woff2') format('woff2');
}
