:root {
  @include use-responsive-sizing(--link-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--link-box-padding-block --link-box-padding-inline, responsive-map(xs 2rem, xl 3rem));

  --link-box-background-color: var(--color-red-100);
  --link-box-gap: 0.5rem;
  --link-box-icon-gap: 1rem;
}

.link-box {
  background-color: var(--link-box-background-color);
  display: flex;
  flex-direction: column;
  font-size: var(--link-box-font-size);
  gap: 1rem;
  line-height: var(--line-height-body);
  padding-block: var(--link-box-padding-block);
  padding-inline: var(--link-box-padding-inline);
}

.link-box__link {
  --link-icon-gap: var(--link-box-icon-gap);
  --link-underline-puffer: 0;
  --link-icon-size: 1em;
  --link-icon-vertical-align: 0;

  display: block;
  font-weight: var(--font-weight-semibold);

  & + & {
    margin-block-start: var(--link-box-gap);
  }
}
