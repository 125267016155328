:root {
  @include use-responsive-sizing(--breaker-button-size, responsive-map(xs 2rem, xl 2.4rem));
  @include use-responsive-sizing(--breaker-gap, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--breaker-headline-font-size, responsive-map(xs 3rem, xl 5rem));
  @include use-responsive-sizing(--breaker-min-height, responsive-map(xs 10rem, xl 25rem));
  @include use-responsive-sizing(--breaker-padding-block, responsive-map(xs 2rem, xl 6rem));
  @include use-responsive-sizing(--breaker-spillover, responsive-map(xs 3rem, xl 5rem));
  @include use-responsive-sizing(--breaker-text-font-size, responsive-map(xs 1.8rem, xl 2.4rem));

  --breaker-background-image: var(--primary-gradient);
  --breaker-color: var(--color-white);
  --breaker-button-color: var(--interactive-color);
}

.breaker {
  background-image: var(--breaker-background-image);
}

.breaker__inner {
  @include use-container();

  align-items: center;
  color: var(--breaker-color);
  display: flex;
  min-height: var(--breaker-min-height);
  padding-block: var(--breaker-padding-block);
  position: relative;
}

.breaker__image {
  aspect-ratio: 1;
  height: calc(100% + var(--breaker-spillover) * 2);
  inset-block-start: calc(var(--breaker-spillover) * -1);
  inset-inline-end: 0;
  position: absolute;
  transform: translateX(25%);
  width: auto;
  z-index: 1;
}

.breaker__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--breaker-gap);
  position: relative;
  text-align: left;
  z-index: 2;
}

.breaker__headline {
  --headline-color: var(--breaker-color);
  --headline-font-weight: var(--font-weight-extrabold);
  --headline-font-size: var(--breaker-headline-font-size);

  max-width: 80rem;
}

.breaker__text {
  --link-color: var(--breaker-color);
  --link-color-engaged: var(--breaker-color);

  font-size: var(--breaker-text-font-size);
  line-height: var(--line-height-body);
  max-width: 60rem;
}

.breaker__button {
  --icon-button-background-color: transparent;
  --icon-button-background-color-hover: transparent;
  --icon-button-icon-size: 1.4em;
  --icon-button-size: 1em;
  --icon-button-text-size: var(--breaker-button-size);
  --icon-button-text-color: var(--breaker-color);
}
