:root {
  --icon-button-background-color-hover: var(--interactive-hover-color);
  --icon-button-background-color: var(--interactive-color);
  --icon-button-border-color: transparent;
  --icon-button-border-width: 0;
  --icon-button-font-weight: var(--font-weight-extrabold);
  --icon-button-gap: 0.8em;
  --icon-button-icon-color: var(--color-white);
  --icon-button-icon-size: 1em;
  --icon-button-line-height: 1.3;
  --icon-button-size: 2.2em;
  --icon-button-text-color: var(--text-color);
  --icon-button-text-size: 1.8rem;
  --icon-button-underline-color-engaged: currentColor;
  --icon-button-underline-color: transparent;
  --icon-button-underline-height: 2px;
  --icon-button-underline-offset: 0.2em;
}

.icon-button {
  align-items: center;
  display: inline-flex;
  font-size: var(--icon-button-text-size);
  gap: var(--icon-button-gap);
  line-height: 1;
  text-align: left;
  user-select: none;

  &:is(:hover:not([disabled]), :focus:not(.has-invisible-focus), .icon-button--active),
  .has-button:is(:hover, :focus-within) &:not([disabled]) {
    --icon-button-background-color: var(--icon-button-background-color-hover);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.icon-button--clipped {
  max-width: 100%;
}

.icon-button__text {
  --underline-color: var(--icon-button-underline-color);
  --underline-color-engaged: var(--icon-button-underline-color-engaged);
  --underline-height: var(--icon-button-underline-height);
  --underline-offset: var(--icon-button-underline-offset);

  color: var(--icon-button-text-color);
  font-size: var(--icon-button-text-size);
  font-weight: var(--icon-button-font-weight);
  line-height: var(--icon-button-line-height);

  .icon-button--clipped & {
    overflow: hidden;
    padding-block: 0.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon-button__icon {
  align-items: center;
  background-color: var(--icon-button-background-color);
  border-color: var(--icon-button-border-color);
  border-style: solid;
  border-width: var(--icon-button-border-width);
  color: var(--icon-button-icon-color);
  display: flex;
  flex-shrink: 0;
  font-size: var(--icon-button-icon-size);
  height: var(--icon-button-size);
  justify-content: center;
  position: relative;
  transition-property: background-color, color;
  width: var(--icon-button-size);
  z-index: 1;
}
