:root {
  --horizontal-rule-color: var(--color-gray-50);
  --horizontal-rule-thickness: 0.5rem;
  --horizontal-rule-width: 100%;
  --horizontal-rule-margin-inline: auto;
  --horizontal-rule-margin-block: 0;
}

.horizontal-rule {
  border-block-start: var(--horizontal-rule-thickness) solid var(--horizontal-rule-color);
  clear: both;
  display: block;
  margin-block: var(--horizontal-rule-margin-block);
  margin-inline: var(--horizontal-rule-margin-inline);
  width: var(--horizontal-rule-width);
}
