// stylelint-disable selector-no-qualifying-type
button::-moz-focus-inner,
input[type='color']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
  border: 0;
  padding-block: 0;
  padding-inline: 0;
}
// stylelint-enable selector-no-qualifying-type

:focus-visible {
  @include use-focus-outline();
}
