:root {
  @include use-responsive-sizing(--podcast-box-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--podcast-box-padding-block --podcast-box-padding-inline, responsive-map(xs 2rem, xl 3rem));
  @include use-responsive-sizing(--podcast-box-badges-inner-gap, responsive-map(xs 1.5rem, xl 2rem));
  @include use-responsive-sizing(--podcast-box-image-offset, responsive-map(xs 3rem, xl 5rem));

  --podcast-box-background-color: var(--color-red-100);
  --podcast-box-gap: 1rem;
  --podcast-box-badges-gap: 3rem;
  --podcast-box-column-gap: 4rem;
  --podcast-box-row-gap: 4rem;
  --podcast-box-rss-feed-gap: 3rem;
  --podcast-box-rss-feed-inner-gap: 2rem;
  --podcast-box-image-offset: 3rem;
  --podcast-box-image-width: clamp(20rem, 33.33%, 30rem);
}

.podcast-box {
  container-name: podcast-box;
  container-type: inline-size;
  padding-block-start: var(--podcast-box-image-offset);
}

.podcast-box__inner {
  background-color: var(--podcast-box-background-color);
  column-gap: var(--podcast-box-column-gap);
  display: flex;
  flex-direction: column;
  font-size: var(--podcast-box-font-size);
  height: 100%;
  line-height: var(--line-height-body);
  padding-block: var(--podcast-box-padding-block);
  padding-inline: var(--podcast-box-padding-inline);
  row-gap: var(--podcast-box-row-gap);

  @container podcast-box (min-width: 60rem) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.podcast-box__image {
  align-self: flex-start;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-block-start: calc(var(--podcast-box-image-offset) * -1 - var(--podcast-box-padding-block));
  width: var(--podcast-box-image-width);
}

.podcast-box__content {
  display: flex;
  flex-direction: column;
  gap: var(--podcast-box-gap);

  @container podcast-box (min-width: 60rem) {
    width: calc(100% - var(--podcast-box-image-width) - var(--podcast-box-column-gap));
  }
}

.podcast-box__badges-rss-feed {
  flex-basis: 100%;
}

.podcast-box__badges {
  display: flex;
  flex-wrap: wrap;
  gap: var(--podcast-box-badges-inner-gap);
}

.podcast-box__badge {
  display: block;
  flex-shrink: 0;
  line-height: 0;
  max-width: 30rem;
  min-width: 20rem;
  width: calc(50% - var(--podcast-box-badges-inner-gap) / 2);

  @container podcast-box (min-width: 60rem) {
    min-width: none;
    width: calc(33.33% - var(--podcast-box-badges-inner-gap) * 0.67);
  }
}

.podcast-box__rss-feed {
  display: flex;
  flex-direction: column;
  gap: var(--podcast-box-rss-feed-inner-gap);
  margin-block-start: var(--podcast-box-rss-feed-gap);

  @include mq($from: m) {
    align-items: center;
    flex-direction: row;
  }
}

.podcast-box__rss-feed-button {
  flex-shrink: 0;
}

.podcast-box__rss-feed-input {
  flex-grow: 1;
  width: 100%;
}
