:root {
  @include use-responsive-sizing(--alert-padding-block --alert-padding-inline, responsive-map(xs 2rem, xl 3rem));

  --alert-background-color: var(--background-color);
  --alert-border-width: 3px;
  --alert-color: var(--text-color);
  --alert-font-size: var(--font-size-default);
  --alert-icon-color: var(--text-color);
  --alert-icon-size: 3em;
  --alert-line-height: var(--line-height-body);
  --alert-z-index: #{z-index('default')};
}

.alert {
  font-size: var(--alert-font-size);
  padding-block-start: calc(var(--alert-icon-size) / 2);
  position: relative;
  z-index: var(--alert-z-index);
}

.alert--info {
  --alert-icon-color: var(--info-color);
}

.alert--success {
  --alert-icon-color: var(--success-color);
}

.alert--warning {
  --alert-icon-color: var(--warning-color);
}

.alert--error {
  --alert-icon-color: var(--error-color);
}

.alert__icon {
  color: var(--alert-icon-color);
  font-size: var(--alert-icon-size);
  inset-block-start: 0;
  inset-inline-start: var(--alert-padding-inline);
  line-height: 0;
  position: absolute;
}

.alert__text {
  background-color: var(--alert-background-color);
  color: var(--alert-color);
  display: block;
  line-height: var(--alert-line-height);
  padding-block: var(--alert-padding-block);
  padding-inline: var(--alert-padding-inline);

  .alert__icon + & {
    padding-block-start: calc((var(--alert-padding-block) + var(--alert-icon-size)) / 2);
  }
}
