:root {
  @include use-responsive-sizing(--accordion-summary-font-size, responsive-map(xs 1.6rem, xl 2rem));
  @include use-responsive-sizing(--accordion-font-size, responsive-map(xs 1.6rem, xl 2rem));

  --accordion-background-color: transparent;
  --accordion-block-spacing: 2rem;
  --accordion-border-color: var(--line-color);
  --accordion-border-width: 2px;
  --accordion-border-width-block: var(--accordion-border-width);
  --accordion-border-width-inline: var(--accordion-border-width);
  --accordion-content-padding-block: 2rem;
  --accordion-icon-color: var(--meta-text-color);
  --accordion-padding-inline: 2rem;
  --accordion-summary-padding-block: 1.5rem;
  --accordion-summary-color: var(--primary-color);
}

.accordion {
  background-color: var(--accordion-background-color);
  border-block-width: var(--accordion-border-width-block);
  border-color: var(--accordion-border-color);
  border-inline-width: var(--accordion-border-width-inline);
  border-style: solid;
  font-size: var(--accordion-font-size);
}

.accordion__summary {
  align-items: center;
  appearance: none;
  color: var(--accordion-summary-color);
  cursor: pointer;
  display: flex;
  font-size: var(--accordion-summary-font-size);
  font-weight: var(--font-weight-bold);
  padding: 1.5rem 2rem;
  padding-block: var(--accordion-summary-padding-block);
  padding-inline: var(--accordion-padding-inline);
  user-select: none;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
}

.accordion__summary-icon {
  color: var(--accordion-icon-color);
  flex-shrink: 0;
  font-size: 1em;
  line-height: 0;
  margin-left: auto;
  transition-property: transform;

  .accordion[open] & {
    transform: rotate(180deg);
  }
}

.accordion__blocks {
  border-block-start: 1px solid var(--accordion-border-color);
  padding-block: var(--accordion-content-padding-block);
  padding-inline: var(--accordion-padding-inline);
}

.accordion__block {
  margin-block-end: var(--accordion-block-spacing);

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: var(--accordion-block-spacing);
  }

  > .headline {
    margin-block-start: calc(var(--accordion-block-spacing) * 1.5);
  }
}

.accordion__block--aside {
  margin-block: calc(var(--accordion-block-spacing) * 1.25);
}
