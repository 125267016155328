:root {
  --footnote-foreground-color: var(--color-gray-800);
  --footnote-background-color: var(--color-gray-100);
}

.footnote {
  display: inline-block;
  margin-inline-end: 0.3em;
  translate: 0 -0.4em;
  vertical-align: 0;
}

.footnote__link {
  background-color: var(--footnote-background-color);
  color: var(--footnote-foreground-color);
  display: inline-block;
  font-size: 0.6em;
  font-weight: var(--font-weight-bold);
  line-height: 1;
  padding-block: 0.1em;
  padding-inline: 0.4em;
  transition-property: background-color, color;

  &:is(:hover, :focus-visible) {
    background-color: var(--footnote-foreground-color);
    color: var(--footnote-background-color);
  }
}
