:root {
  @include use-responsive-sizing(--section-heading-padding-block, responsive-map(xs 4rem, xl 5rem));
  @include use-responsive-sizing(--section-heading-font-size, responsive-map(xs 3rem, xl 4.5rem));
  @include use-responsive-sizing(--section-padding-block, responsive-map(xs 4rem, xl 6rem));
  @include use-responsive-sizing(--section-content-gap, responsive-map(xs 3rem, xl 5.5rem));
  @include use-responsive-sizing(--section-block-gap, responsive-map(xs 2rem, xl 4rem));
  @include use-responsive-sizing(--section-headline-gap, responsive-map(xs 2rem, xl 2.5rem));
  @include use-responsive-sizing(--section-text-size, responsive-map(xs 1.8rem, xl 2rem));
  @include use-responsive-sizing(--section-intro-text-size, responsive-map(xs 1.8rem, xl 2.6rem));

  --section-background-color: transparent;
  --section-text-max-width: 80rem;
  --section-column-gap: var(--gap);
  --section-button-gap: 3rem;
  --section-text-gap: 1.5rem;
  --breaker-heading-background-image: var(--primary-gradient);
  --breaker-heading-background-color: transparent;
  --breaker-heading-color: var(--color-white);
}

.section {
  background-color: var(--section-background-color);
}

.section--with-background {
  --section-background-color: var(--color-gray-50);
  --list-teaser-background-color: var(--color-white);
  --list-teaser-event-background-image: var(--secondary-gradient);
  --list-teaser-event-date-color: var(--color-white);
  --list-teaser-event-place-color: var(--color-white);
}

.section__heading {
  --headline-color: var(--breaker-heading-color);
  --headline-font-weight: var(--font-weight-extrabold);
  --headline-font-size: var(--section-heading-font-size);

  background-color: var(--breaker-heading-background-color);
  background-image: var(--breaker-heading-background-image);
}

.section__heading-inner {
  @include use-container();

  margin-inline: auto;
  padding-block: var(--section-heading-padding-block);
}

.section__inner {
  @include use-container();

  padding-block: var(--section-padding-block);

  .section__heading + & {
    padding-block-start: calc(var(--section-padding-block) * 0.75);
  }

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.section__headline {
  --headline-font-weight: var(--font-weight-extrabold);

  grid-area: grid-headline;
  margin-block: var(--section-headline-gap);
}

.section__text {
  font-size: var(--section-text-size);
  margin-block: var(--section-text-gap);
  max-width: var(--section-text-max-width);
}

.section__text--intro {
  grid-area: grid-intro-text;

  .section:not(.section--gridded) & {
    font-size: var(--section-intro-text-size);
  }

  .section--highlight:not(.section--gridded) & {
    @include use-responsive-sizing(width, (
      m: column-width(m, 10),
      l: column-width(l, 10),
      xl: column-width(xl, 10),
    ));

    font-weight: var(--font-weight-bold);
    margin-inline: auto;
    max-width: none;
  }
}

.section__text--outro {
  grid-area: grid-outro-text;
}

.section__content {
  grid-area: grid-content;
  margin-block: var(--section-content-gap);

  @include mq($until: s) {
    --list-teaser-padding-inline: var(--inner-container-padding);
    --logo-wall-logo-padding-inline: var(--inner-container-padding);
    --tile-padding-inline: var(--inner-container-padding);
    --teaser-padding-inline: var(--inner-container-padding);

    margin-inline: calc(var(--inner-container-padding) * -1);
  }
}

.section__block {
  margin-block-end: var(--section-block-gap);

  & + & {
    margin-block-start: calc(var(--section-block-gap) * 1.5);
  }

  &:first-child {
    margin-block-start: 0;
  }

  &:last-child {
    margin-block-end: 0;
  }

  > * + * {
    margin-block-start: 1rem;
  }

  > .headline {
    margin-block-start: calc(var(--section-block-gap) * 1.5);
  }
}

.section__button {
  grid-area: grid-button;
  margin-block: var(--section-button-gap);
}

@include mq($from: m) {
  .section--gridded {
    @include use-responsive-sizing(--section-content-column-width, (
      m: column-width(m, 7),
      l: column-width(l, 7),
      xl: column-width(xl, 7),
    ));

    @include use-responsive-sizing(--section-content-column-gap, (
      m: 0,
      l: column-width(l, 1),
      xl: column-width(xl, 1),
    ));

    --section-content-gap: 0;

    .section__inner {
      column-gap: var(--section-column-gap);
      display: grid;
      grid-template-areas:
        'grid-headline . grid-content'
        'grid-intro-text . grid-content'
        'grid-outro-text . grid-content'
        'grid-button . grid-content'
        '. . grid-content';
      grid-template-columns: auto var(--section-content-column-gap) var(--section-content-column-width);
      grid-template-rows: min-content min-content min-content min-content auto;
    }
  }
}
