:root {
  @include use-responsive-sizing(--teaser-icon-size, responsive-map(xs 2.2rem, xl 3rem));

  --teaser-background-color: var(--color-red-100);
  --teaser-content-background-color: var(--color-white);
  --teaser-content-padding: 2rem;
  --teaser-font-size: 1.6rem;
  --teaser-headline-color-hover: var(--text-color);
  --teaser-headline-color: var(--headline-color);
  --teaser-padding-block: 2rem;
  --teaser-padding-inline: 2rem;
  --teaser-z-index: #{z-index('default')};
}

.teaser {
  background-color: var(--teaser-background-color);
  container-name: teaser;
  container-type: inline-size;
  position: relative;
  z-index: var(--teaser-z-index);
}

.teaser__inner {
  align-items: flex-end;
  aspect-ratio: 1;
  display: flex;
  justify-content: flex-end;
  padding-block: var(--teaser-padding-block);
  padding-inline: var(--teaser-padding-inline);

  &:is(:hover, :focus-within) {
    --teaser-headline-color: var(--teaser-headline-color-hover);
  }

  &:focus-within {
    @include use-focus-outline();
  }

  @container teaser (min-width: 40rem) {
    aspect-ratio: 3 / 2.5;
  }

  @container teaser (min-width: 60rem) {
    aspect-ratio: 3 / 2;
  }
}

.teaser__image {
  --image-z-index: 1;

  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.teaser__content {
  background-color: var(--teaser-content-background-color);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: var(--teaser-font-size);
  gap: 0.5em;
  padding: var(--teaser-content-padding);
  transition-property: background-color;
  width: 100%;
  z-index: 2;

  @container teaser (min-width: 40rem) {
    font-size: calc(var(--teaser-font-size) * 1.125);
    width: 75%;
  }
}

.teaser__headline {
  --focus-outline-width: 0;
  --headline-color: var(--teaser-headline-color);

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.teaser__icon {
  color: var(--teaser-headline-color);
  font-size: var(--teaser-icon-size);
  margin-block-start: calc((var(--line-height-small) * var(--teaser-icon-size) - var(--teaser-icon-size)) / 2);
}

.teaser__description {
  line-height: var(--line-height-body);
}

.teaser__copyright {
  display: flex;
  inset-block-start: var(--teaser-padding-block);
  inset-inline-end: var(--teaser-padding-inline);
  position: absolute;
  z-index: 3;

  @container teaser (min-width: 40rem) {
    inset-block-end: var(--teaser-padding-block);
    inset-block-start: auto;
    inset-inline-end: auto;
    inset-inline-start: var(--teaser-padding-inline);
  }
}
